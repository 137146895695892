@import 'styles/variables';

.etc-selector {
  &__option {
    &:hover {
      background-color: $color-list-item-hover;
    }

    &:first-child {
      border-radius: 0.2rem 0.2rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 0.2rem 0.2rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-gray--lightest;
    }
  }
}

.etc-selector-option,
.etc-menu-item {
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 2.5rem;
}

.etc-menu-item {
  cursor: pointer;
  font-size: $font-size--button;
  font-weight: $font-weight--light;

  &:hover {
    background-color: $color-list-item-hover;
  }

  &:active {
    color: $color-gray;
  }

  &.disabled,
  &:disabled {
    color: $color-gray;
    opacity: 0.65;

    &:hover {
      color: $color-gray;
      background-color: initial;
    }
  }

  &__icon.fa {
    margin: 0.45rem;
    margin-left: 0;
    margin-bottom: 0.55rem;
  }
}

.etc-selector-option {
  &__check-icon.fa {
    margin: 0.75rem 0.5rem 0;
    color: $color-gray--light;
    font-size: 0.85rem;
    padding: 0.12rem;
    align-self: self-start;
  }

  &__label {
    font-size: $font-size--button;
    font-weight: $font-weight--light;
    user-select: none;
    padding: 0.5rem 0;
  }

  &__sub-label {
    font-size: $font-size--small;
    font-weight: $font-weight--light;
    color: $color-text--gray;
  }

  &--selected {
    .etc-selector-option__check-icon.fa {
      background-color: $color-brand-blue--highlight !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &--selected,
  &:hover {
    .etc-selector-option__check-icon.fa {
      color: $color-white;
      border-radius: 50%;
    }
  }

  &:hover {
    .etc-selector-option__check-icon.fa {
      background-color: $color-gray--light;
    }
  }

  &:focus {
    .etc-selector-option__label {
      text-decoration: underline;
    }
  }
}
