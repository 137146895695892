.PersonList-placeholder {
  min-height: 2.5rem;
}

.PersonList {
  display: flex;
  flex-direction: row-reverse;

  .avatar {
    position: relative;
    margin: 0;
    margin-left: -0.75rem;

    &:hover {
      transform: scale(1.15, 1.15);
      z-index: 1;
    }

    &.is-host {
      &::after {
        background: $color-brand-blue;
        border: 1px solid $color-white;
        border-radius: 0.3125rem;
        content: '';
        display: block;
        height: 0.625rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 0.625rem;
      }
    }
  }
}
