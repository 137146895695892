@import 'styles/variables';

.none {
  font-style: italic;
  color: $color-text--gray;
  margin-right: $spacing--small;
}

.container {
  display: flex;
  align-items: center;
  font-size: $font-size--medium;
  font-weight: $font-weight--light;
}

.editContainer {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.boltContainer {
  margin-top: $spacing--tiny;
  margin-right: $spacing--small;
  height: 1.75rem;
  width: 1.75rem;
  fill: $color-gray;
}
