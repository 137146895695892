@import 'styles/variables';

.reportOptions {
  background: $color-gray--lightest;
  border-radius: $border-radius-xlg;
  margin-bottom: 2rem;
  padding: 2rem;
}

.legend {
  font-size: 1.2rem;
}

.roleSelector,
.inclusiveContainer {
  display: inline-block;
  margin-right: 2rem;
  vertical-align: middle;
  width: 40%;
}

.checkboxContainer {
  margin-bottom: 1rem;
  max-height: 1rem;
}

.includesArea {
  align-content: flex-start;
  column-gap: 5rem;
  display: flex;
  flex-flow: column wrap;
  max-height: 10rem;
}

.bulkSelect {
  margin-left: 4rem;
}
