@import 'styles/variables';

.base {
  composes: form-control from global;
}

.warning {
  border-color: $color-red;
}

.errorMessage {
  color: $color-red;
  margin-top: $spacing--tiny;
}

.block {
  display: block;
}
