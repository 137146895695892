@import 'styles/variables';
@import '../../variables';

.sideBar {
  color: $color-white;
}

@media only screen and (min-width: 768px) {
  .sideBar {
    display: flex;
    color: $color-white;
    height: 100vh;
    min-height: 31rem;
  }

  .staticBlock {
    width: $static-block-width;
    display: flex;
    flex-direction: column;
  }
}

.staticBlock {
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0.5rem 0.5rem;
  background-color: $color-brand-black;
  z-index: 1;

  @media only screen and (max-width: 767px) {
    padding: 0.2rem 0.3rem;
  }
}

.collapsibleBlock {
  background-color: var(--theme-primary);
  overflow-x: hidden;
  overflow-y: auto;

  @media only screen and (max-width: 767px) {
    transition: height $animation-params;
    height: 96vh;
    overflow-y: auto;
  }

  @media only screen and (min-width: 768px) {
    transition: width $animation-params;
    width: $side-bar-width;
  }

  &Closed {
    width: 0;
  }

  &::-webkit-scrollbar {
    width: $spacing--small;
    margin-left: $spacing--tiny;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3rem;
    background: rgb(0 0 0 / 20%);
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 30%);
    border-radius: 3rem;
  }
}

.collapsibleBlockContent {
  height: 100%;
  min-width: calc(#{$side-bar-width} - 0.5rem);
  background-color: var(--theme-primary);
  transition: transform $animation-params;
  transform: none;

  &Closed {
    transform: translate(-$side-bar-width);
  }
}
