@import 'styles/variables';

.grid {
  display: grid;
}

.gridMobile {
  grid-template-areas:
    'boltIcon editButton'
    'body     body'
    'details  details';
  grid-template-columns: 1fr min-content;
}

.gridFull {
  grid-template-areas:
    'boltIcon body    editButton'
    'boltIcon details editButton';
  grid-template-columns: min-content 1fr min-content;
  grid-column-gap: $spacing--small;
}

.boltContainer {
  grid-area: boltIcon;
  margin-top: $spacing--tiny;
  height: 1.75rem;
  width: 1.75rem;
  fill: $color-gray;
}

.details {
  grid-area: details;
}

.editButton {
  grid-area: editButton;
  height: 100%;
}

.body {
  grid-area: body;
  max-height: 6.5rem;
  overflow-y: auto;
  margin-bottom: $spacing--tiny;

  // hack for OS X cause OS X hides scroll bar by default - https://stackoverflow.com/a/31278448
  // issue: https://www.pivotaltracker.com/story/show/155105736/comments/188281293
  &::-webkit-scrollbar {
    appearance: none;
    width: 0.44rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background-color: rgb(0 0 0 / 50%);
    box-shadow: 0 0 0.0625rem rgb(255 255 255 / 50%);
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-text--gray;
  margin-bottom: $spacing--tiny;
  font-weight: $font-weight--light;
  font-size: $font-size--base;
}

.button {
  composes: btn-reset from global;
  display: inline-flex;
  border-radius: $spacing--tiny;
  padding: $spacing--tiny;
  border: 1px solid transparent;
  cursor: pointer;

  svg {
    height: 1.25rem;
    width: 1.25rem;
    fill: $color-icon-button;
  }

  &:hover,
  &:focus {
    background-color: $color-icon-button--background-hover;

    svg {
      fill: $color-brand-blue;
    }
  }

  &:focus {
    border: 1px solid $color-gray;
    outline: none;
  }
}
