@import 'styles/variables';

.wrapper {
  width: 0.5rem;
}

.enabled {
  opacity: 0.65;
  fill: $color-gray--light;
}

.disabled {
  opacity: 0.1;
  fill: $color-gray--lighter;
}
