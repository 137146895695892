@import 'styles/variables';

.markdownText {
  font-size: $font-size--base;
  font-weight: $font-weight--medium;

  ul {
    list-style: disc;
    margin-left: $spacing--medium;
  }

  .linkContainer {
    margin-top: $spacing--medium;
  }
}
