.proposal-summary-list-item {
  display: flex;
  padding-bottom: $spacing--small;

  p {
    display: inline;
  }

  &__icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.5rem;
    background-color: $color-gray--lighter;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    opacity: 0.85;

    svg {
      height: 1rem;
      width: 1rem;
    }

    &--adding {
      background-color: $color-proposal-adding-green--background;

      svg {
        fill: $color-proposal-adding-green;
      }
    }

    &--changing,
    &--moving-in,
    &--moving-out {
      background-color: $color-proposal-changing-blue--background;

      svg {
        fill: $color-proposal-changing-blue;
      }
    }

    &--removing {
      background-color: $color-proposal-removing-red--background;

      svg {
        fill: $color-proposal-removing-red;
      }
    }
  }

  &__title {
    &--removed-item {
      text-decoration: line-through;
      white-space: pre-line;
      color: $color-gray;
    }

    &--added-item {
      text-decoration: underline;
      background-color: $color-proposal-adding-green--background;
    }
  }
}
