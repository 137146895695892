@import 'styles/variables';
@import 'styles/variables-colors';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 0;
  width: 100%;
}

.label {
  display: flex;
  //margin-bottom: $spacing--default;
  font-weight: $font-weight--default;
  font-size: $font-size--base;
  color: $color-text--gray;
}

.inputContainer {
  position: relative;
  display: block;
}
