@import 'styles/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h3 {
    margin-bottom: 0;
  }
}

.linkToNote {
  i {
    margin-right: 0.5rem;
  }

  &:hover {
    i {
      color: $color-brand-blue;
    }
  }
}
