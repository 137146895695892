@import 'styles/variables';

.tensionInfo {
  background-color: $color-background-blue--light;
  padding: $spacing--default;
  border-radius: $spacing--tiny;
}

.marginBottomMedium {
  margin-bottom: $spacing--medium;
}
