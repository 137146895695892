// This is a UL reset since we use lists in a lot of places and don't want bullets to show in all cases

/* stylelint-disable no-descending-specificity */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.bulleted,
ul.bulleted-small {
  list-style-type: disc;
  margin: 0;
  padding-left: 1.3em;

  p:last-child {
    margin-bottom: 0;
  }
}

ul.bulleted-small {
  padding-top: 0.325rem;
  padding-bottom: 0.325rem;
}

ul.bulleted-small li {
  padding: 0.45rem 0 0;
}

.line-item .controls {
  display: inline-block;
  opacity: 0.5;
  white-space: nowrap;
  position: absolute;
  right: 0.625rem;
}

.line-item:hover .controls {
  opacity: 1;
}

.list {
  margin: 0 0 1rem;
}

.list + .list {
  margin: 1rem 0;
}

.list--default,
.list--bulleted {
  list-style-type: disc;
  margin: 0;
  padding-left: 1.25rem;

  li p:last-child {
    margin-bottom: 0;
  }
}

.list--spaced {
  li {
    margin-bottom: 0.625rem;
  }
}

// Rob TODO - Refactor these lists so we instead nest lists inside a parent box element
// rather than assuming we want the box styling. If nested the list--header, etc
// will automatically assume the box-styling.

.list--divided--not-box {
  width: 100%;

  .list__header {
    font-size: $font-size--body;
    font-weight: $font-weight--heavy;
    padding: 0.5rem 0.625rem;
    text-align: left;

    .icon,
    .fa {
      color: $color-gray--lighter;
      line-height: 1;
      margin-right: 0.625rem;
    }

    input[type='checkbox'] {
      margin: 0;
    }
  }

  .list__body {
    line-height: $line-height--small;
  }

  .inline__item {
    display: inline-block;
  }

  li,
  .list__item {
    border-bottom: 1px solid $color-border;
    font-size: $font-size--body;
    padding: 0.75rem 1rem;

    &.no-border {
      border-top: none;
      padding-top: 0.187rem;
    }

    .controls {
      display: block;
      opacity: 0.35;
      white-space: nowrap;

      .icon,
      .fa {
        margin-right: 0;
        color: $color-gray--lighter;
      }
    }

    &:last-child {
      border-bottom: none;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    p:last-child {
      margin: 0;
    }

    .icon,
    .fa {
      line-height: 1;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }

    &:hover {
      background: $color-list-item-hover;

      .controls {
        opacity: 1;
      }

      a.icon,
      a.fa {
        color: $color-brand-blue;
      }
    }
  }

  .person {
    height: 1.5rem;
  }

  .button {
    margin: -0.25rem 0;
  }
}

.list--divided {
  @extend .box;

  width: 100%;

  .list__header {
    @extend .box__header;

    font-weight: $font-weight--heavy;
    text-align: left;

    .icon,
    .fa {
      color: $color-gray--lighter;
      line-height: 1;
      margin-right: 0.625rem;
    }

    input[type='checkbox'] {
      margin: 0;
    }
  }

  .list__body {
    line-height: $line-height--small;
  }

  .inline__item {
    display: inline-block;
    vertical-align: top;
  }

  li,
  .list__item {
    border-bottom: 1px solid $color-border;
    font-size: $font-size--body;
    padding: 0.75rem 1rem;

    &.no-border {
      border-top: none;
      padding-top: 0.187rem;
    }

    .controls {
      display: block;
      opacity: 0.35;
      white-space: nowrap;

      .icon,
      .fa {
        margin-right: 0;
        color: $color-gray--lighter;
      }
    }

    &:last-of-type {
      border-bottom: none;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    p:last-child {
      margin: 0;
    }

    .icon,
    .fa {
      line-height: 1;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }

    &:hover {
      background: $color-list-item-hover;

      .controls {
        opacity: 1;
      }

      a.icon,
      a.fa {
        color: $color-brand-blue;
      }
    }

    &.is-completed {
      background: $color-gray--lightest;
      color: #999;

      &:hover {
        background: $color-gray--lightest;
      }
    }
  }

  .btn-sm {
    margin-top: -0.625rem;
    top: 0.187rem;
    position: relative;
  }

  .person {
    height: 1.25rem;
    top: -0.125rem;
    position: relative;
  }

  .button {
    margin: -0.25rem 0;
  }
}

.list--box {
  .list-item--inner {
    padding: 1rem;
  }
}

.list--sidebar {
  li {
    border-bottom: 1px solid $color-border;
  }

  a {
    border-left: 0 solid transparent;
    display: block;
    padding: 0.375rem 0.625rem;
    position: relative;
    transition: background 0.15s ease-in-out;

    &:hover {
      background: $color-list-item-hover;
    }
  }
}

/* stylelint-enable no-descending-specificity */
