@import 'styles/variables';

.buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-left: 2rem;

  > button {
    padding: 0.5rem;
  }
}
