@import 'styles/variables';

button.header {
  cursor: pointer;
}

.header {
  opacity: 0.8;
  width: 100%;
  text-align: left;
  font-size: $font-size--base;
  padding: 1rem 1.25rem;
  display: flex;
  justify-content: space-between;
}

.childrenContainer {
  width: 100%;
}

.headerInner {
  width: 100%;
}

.arrow {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;

  svg {
    fill: $color-gray;
  }
}

.header:focus {
  outline: none;
}

.header:hover {
  .arrowInteractive {
    svg {
      fill: $color-brand-blue--hover;
    }
  }
}

button.header:hover {
  background: $color-list-item-hover;
}

.header.selected {
  background: $color-gray--lightest;
  opacity: 1;

  &:hover {
    background: $color-gray--lightest;
  }
}
