@import 'styles/variables';

.container {
  margin-top: $spacing--medium;
  font-size: $font-size--medium;
}

.label {
  font-weight: $font-weight--heavy;
}
