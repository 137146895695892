$one-pixel: 0.0625rem;

// Here is the idea of making text visually hidden https://a11y-101.com/development/icons-and-links
// Here is hidden class implementation https://a11y-101.com/development/skip-link
.hidden {
  clip: rect($one-pixel, $one-pixel, $one-pixel, $one-pixel);
  height: $one-pixel;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: $one-pixel;

  &:focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: absolute;
    width: auto;
  }
}
