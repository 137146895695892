.refinery-role-footer {
  width: 100%;

  &__expand-role-checkbox,
  &__destroy-role-checkbox {
    display: inline-block;
    margin-right: 1.5rem;
  }

  &__move-items {
    margin-top: 1rem;
  }
}
