@import 'styles/variables';

.noteCard {
  border: 1px solid $color-border;
  border-radius: $border-radius;
  margin: $spacing--default 0;
  padding: 1rem;
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 767px) {
    max-width: 94vw;
  }
}

.noteCardNoBorder {
  border: none;
}

.noteCardNoPadding {
  padding: 0;
}

.content {
  flex: 1;
  align-self: center;
}
