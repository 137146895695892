@import 'styles/variables';

.container {
  position: relative;
}

.fakeHeader {
  position: absolute;
}

.header {
  transition: background-color 0.3s ease-in-out;
  position: sticky;
  top: 0;
  z-index: 2;
}

.floatingHeader {
  background-color: $color-white;
}

.gridLayout {
  display: flex;

  & > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.statusHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  font-weight: $font-weight--light;
  font-size: $font-size--large;
  margin-bottom: $spacing--tiny;
}

.bodySection {
  margin-top: $spacing--default;
  margin-bottom: $spacing--large;
}

.statusDot {
  margin-right: $spacing--small;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
}

.current {
  color: var(--theme-success);

  .statusDot {
    background-color: var(--theme-success);
  }
}

.waiting {
  color: var(--theme-warning);

  .statusDot {
    background-color: var(--theme-warning);
  }
}

.done {
  color: var(--theme-info);

  .statusDot {
    background-color: var(--theme-info);
  }
}

.future {
  color: $color-text--gray;

  .statusDot {
    background-color: $color-text--gray;
  }
}

.archiveArea {
  transition: margin-top 0.3s ease-in-out;
}

.archiveAreaHidden {
  margin-top: 0;
}

.archiveAreaShown {
  margin-top: $spacing--medium;
}

.emptySpace {
  height: 10rem;
}
