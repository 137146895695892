@import 'styles/variables';

.addButton {
  composes: btn from global;
  composes: btn-primary from global;
  background-color: $color-link;
  border-radius: $border-radius;
  padding: 0.25rem;
  display: flex;

  svg {
    fill: $color-white;
    height: 1.5rem;
    width: 1.5rem;
  }
}
