@import 'styles/variables';

.container {
  width: 100%;
  display: block;
}

.body {
  opacity: 0.85;
  width: 100%;
  padding-bottom: 0.5rem;
  border-top: 1px solid $color-border--medium;
}

.bodyContent {
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  vertical-align: middle;
  width: 100%;
  font-size: $font-size--body;
}

.card {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid $color-border;
}

.card.selected {
  border-color: $color-border;
  box-shadow: 0 0.25rem 1rem -0.125rem $color-border;

  span[class*='label'],
  label {
    text-transform: none;
  }
}

@media print {
  .card {
    break-inside: avoid;
  }
}
