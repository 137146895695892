@import 'styles/variables';

.checkboxInput {
  display: none;
}

.checkboxIcon {
  grid-column: 1;
  width: 1rem;
  height: 1rem;
  border: 1px solid $color-gray--light;
  border-radius: $border-radius;
  background-color: $color-white;
  align-self: flex-start;
  margin-top: 0.2rem;
  transition: background-color 150ms, box-shadow 300ms;
  display: flex;

  svg {
    width: 100%;
    height: 100%;
    fill: $color-white;
    transform: scale(1.5);

    path {
      stroke: $color-white;
      stroke-width: 3.125rem;
      stroke-linejoin: round;
    }
  }
}

.checkboxTitle {
  grid-column: 2;
  margin-left: 0.5rem;
  overflow: hidden;
  overflow-wrap: break-word;
  font-size: 1rem;
  font-weight: 300;
  color: $color-gray--light;
}

.checkboxChecked {
  .checkboxIcon {
    border-color: $color-brand-blue;
    background-color: $color-brand-blue;
  }
}

.checkboxDisabled {
  cursor: not-allowed;

  &:hover,
  &:focus {
    .checkboxIcon {
      box-shadow: none;
    }
  }
}

.checkboxChecked .checkboxDisabled .checkboxIcon {
  border-color: $color-gray--light;
  background-color: $color-gray--light;
}

.checkbox {
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  margin-left: $spacing--xtiny;

  &:hover,
  &:focus {
    .checkboxIcon {
      box-shadow: $input-box-shadow;
    }
  }
}
