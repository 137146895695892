.refinery-box {
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: 1px solid $color-border;
  padding: 0 0.1rem;

  .refinery-section__description {
    padding-right: 1rem;
    color: $color-text--gray;
    font-weight: $font-weight--light;
    font-size: 1rem;
    letter-spacing: 0.035rem;
  }
}

.refinery-section {
  padding: $spacing--large 0;
  display: flex;
  flex-direction: row;

  &__toolbar {
    position: absolute;
    top: $spacing--tiny;
    right: 0.05rem;
  }

  &__error {
    margin-top: 1.8rem;
  }

  &__left-column {
    flex-basis: 11rem;
    flex-shrink: 0;
    padding-right: 0.6rem;
  }

  &__content {
    flex-grow: 1;
  }

  @media (max-width: $width-sm) {
    flex-direction: column;

    &__left-column {
      flex-basis: unset;
      padding-bottom: 1em;
    }
  }
}

.refinery-section__row {
  display: flex;

  label {
    flex-grow: 10;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .refinery-accordian-field__top {
    margin-bottom: 0.2rem;
  }

  .ui-definition-tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: $refinery-right-col-width;
    padding-right: 0.5rem;
  }
}

.refinery-input {
  padding: 0.75rem;
  background: $color-input-background;

  &--strikethrough {
    text-decoration: line-through;
  }
}

.refinery-value-pill {
  @mixin value-pill-color($color) {
    margin-left: $spacing--tiny;
    width: calc(100% - 0.25rem);
    box-shadow: -0.187rem 0 0 $color;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 0.75rem;
  }

  &--changed {
    @include value-pill-color($color-proposal-changing-blue);

    background-color: $color-proposal-input-changed;
  }

  &--deleted {
    @include value-pill-color($color-proposal-removing-red);

    background-color: $color-proposal-input-changed;
    text-decoration: line-through;
  }

  &--new {
    @include value-pill-color($color-proposal-adding-green);

    background-color: $color-white;
    background-color: $color-proposal-input-changed;
  }

  &--unchanged {
    padding-left: 1rem; // must adjust for missing 0.25rem left-box-shadow in value-pill-color
  }
}

.refinery-select {
  border-radius: $border-radius;
  width: 100%;
  padding-left: 0;

  .Select-placeholder {
    background: transparent !important; /* stylelint-disable-line declaration-no-important */
  }

  &.is-disabled {
    .Select__control {
      cursor: not-allowed;
      background-color: $color-gray--lightest !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &.refinery-value-pill--changed {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .Select__control {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.is-focused:not(.is-open) > .Select__control {
    border-color: $color-brand-blue !important; /* stylelint-disable-line declaration-no-important */
    box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
  }

  .Select__clear-indicator,
  .Select__dropdown-indicator {
    background: transparent;
  }

  &--strikethrough {
    .Select__value-container {
      text-decoration: line-through;
    }
  }
}
