@import 'styles/variables';

@keyframes loading {
  0% {
    transition: none;
  }

  33% {
    background-color: transparent;
    transition: all 2s ease-out;
  }

  66% {
    transition: none;
  }
}

.section {
  composes: side-bar-section from global;
  white-space: pre-wrap;

  div {
    position: relative;
    background-color: $color-white;
    animation-name: loading;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    opacity: 0.5;
  }
}

.collapsedSection {
  display: flex;
  height: 2.75rem;
  width: 2.75rem;
  margin-bottom: 0.15rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: $color-brand-black--highlight;
  }

  div {
    position: relative;
    background-color: $color-white;
    animation-name: loading;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    opacity: 0.5;
  }
}

.icon {
  height: 1.75rem;
  margin: 0.5rem;
  width: 1.75rem;
  border-radius: 50%;
  background-color: $color-brand-black;
}
