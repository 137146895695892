@import 'styles/variables';

.container {
  display: flex;
}

.boltContainer {
  display: flex;
  flex-direction: column;
  margin-right: $spacing--medium;
  align-items: center;
}

.tension {
  font-size: $font-size--medium;
  font-weight: $font-weight--medium;
  margin-bottom: $spacing--tiny;
}

.boltIcon {
  height: 3rem;
  width: 3rem;
  fill: $color-gray;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.closeButton {
  margin-left: $spacing--medium;
}
