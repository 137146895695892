// this is all we need for the new proposal builder
.gov-mtg-agenda-item-workspace {
  margin-left: $spacing--huge;

  @media (max-width: $width-md) {
    margin-left: 0;
    margin-top: $spacing--huge;
  }

  > .loading-dots {
    margin: 15rem auto;
  }
}

// needed for the governance meeting itself until we can move from old UI
.governance_meetings.main.new {
  .page__breadcrumb {
    display: none;
  }

  .page-header__body {
    padding-bottom: 0;
    margin-bottom: $spacing--large;
  }

  .page__content {
    margin-top: 1rem;
  }

  .u-sticky-top-padded {
    z-index: 3; // prevents dropdowns in the sticky container from going behind the workspace
  }

  .refinery-box:first-of-type {
    border-top: none;

    > .refinery-section {
      padding-top: 0;
    }
  }

  #waiting-for-proposal {
    margin-top: 1.2rem;
  }
}
