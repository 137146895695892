@import 'styles/variables';

.prompt {
  composes: user-content from global;
  list-style-type: none;
  list-style-position: inside;
  padding: $spacing--small 0;
}

.actionSpace {
  height: $spacing--small;
}
