@import '../../../variables';

.icon {
  transition: transform $animation-params;
  transform: none;

  &Flipped {
    transform: rotate(-180deg);
  }
}
