@import 'styles/variables';

.section {
  display: grid;
  grid-template:
    'icon title'
    'icon description'
    'icon button'
    / 1fr 11fr;
  padding: 1rem 0;
  border-bottom: 1px solid $color-border;
  font-size: 1rem;
}

.sectionWithMarginRight {
  margin-right: 1rem;
}

.title {
  grid-area: title;
}

.icon {
  grid-area: icon;
  justify-self: center;
}

.description {
  grid-area: description;
}

.button {
  grid-area: button;
  margin-top: 1rem;
}
