@import '../variables';

.tippy-popper .tippy-tooltip {
  background-color: $color-tooltip-background;
  color: $color-white;
  max-width: 20rem;
  padding: calc(#{$tooltip-background-padding} - 0.25rem);
  text-align: left;
}

.tippy-popper[x-placement^='top'] .tippy-tooltip .tippy-arrow {
  border-top-color: $color-tooltip-background;
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip .tippy-arrow {
  border-bottom-color: $color-tooltip-background;
}

.tippy-popper[x-placement^='left'] .tippy-tooltip .tippy-arrow {
  border-left-color: $color-tooltip-background;
}

.tippy-popper[x-placement^='right'] .tippy-tooltip .tippy-arrow {
  border-right-color: $color-tooltip-background;
}
