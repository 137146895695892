@import 'styles/variables';

.setupModeBanner {
  background-color: $color-navy;

  :global .banner__content {
    display: flex;
    justify-content: center;
  }
}

.launchButton {
  composes: btn-reset from global;
  composes: btn-link from global;
  margin-left: $spacing--tiny !important; /* stylelint-disable-line declaration-no-important */
  font-weight: $font-weight--default;
}
