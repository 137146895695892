@import 'styles/variables';

.step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing--default;
  margin-top: 1.25rem;
}

.title {
  font-size: $font-size--large;
}

.caption {
  font-size: $font-size--base;
}

ul.errors {
  color: $color-red;
  margin-top: $spacing--small;
}

ul.warnings {
  color: $color-orange;
  margin-top: $spacing--small;
}
