$fa-font-path: '../../assets/fonts' !default;

@import '../../assets/stylesheets/vendor/fontawesome/_variables.scss';
@import '../../assets/stylesheets/vendor/fontawesome/_path.scss';

:global {
  @import '../../assets/stylesheets/vendor/fontawesome/font-awesome.scss';
  @import '../../../node_modules/tippy.js/dist/tippy';
  @import 'vendor/tippy';
  @import 'vendor/react-select';
  @import 'utils';
  @import 'legacy/index';
}
