@import 'styles/variables';

.pageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;

  .invitationAcceptedContainer {
    display: grid;
    grid-template-rows: 3fr 1fr 1fr;
    grid-gap: $spacing--medium;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;

    h1 {
      text-align: center;
      margin: 0;
    }

    .iconStyles {
      border-radius: 50%;
      width: 10rem;
    }
  }
}
