@import 'styles/variables';

.container {
  margin: auto;
  background-color: $color-gray--lighter;
  border-radius: 50%;
}

.image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  // to hide alt when image loading failed
  text-indent: -9999rem;
}

.smaller {
  height: 1.75rem;
  width: 1.75rem;
}

.small {
  height: 2rem;
  width: 2rem;
}

.medium {
  height: 2.5rem;
  width: 2.5rem;
}

.xLarge {
  height: 7rem;
  width: 7rem;
}

.redBorder {
  border: 2px solid $color-red;
}

.overlay {
  background-color: rgb(255 255 255 / 50%);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
