/* stylelint-disable no-descending-specificity */

.project-with-actions {
  .btn-group {
    align-items: center;
  }
}

.project-actions {
  cursor: auto;
  margin-top: $spacing--small;

  &:not(.project-actions__foldable) {
    padding: $spacing--small;
    background: $color-background-gray--lighter;
  }
}

.project__action {
  margin: 0;
  padding: 0;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--in-edit {
    background-color: transparent !important; /* stylelint-disable-line declaration-no-important */

    .project__action-description,
    .project__action-description a {
      text-decoration: none !important; /* stylelint-disable-line declaration-no-important */
    }

    .project__action-description {
      border: 1px solid $color-brand-blue--highlight !important; /* stylelint-disable-line declaration-no-important */
      padding-left: $spacing--tiny;
      margin-right: 5rem;
      border-radius: $border-radius;
      background-color: $color-white;
    }
  }

  &--completed {
    .project__action-description,
    .project__action-description a {
      text-decoration: line-through;
      color: $color-gray--light;
    }

    &:hover {
      .project__action-description,
      .project__action-description a {
        color: $color-gray;
      }
    }
  }

  &--new {
    @extend .btn-link;

    cursor: pointer;
    color: $color-link;

    &:hover {
      color: $color-brand-blue--hover;
    }
  }

  &--disabled {
    cursor: default;
    pointer-events: none;
  }

  &-description {
    margin-left: $spacing--tiny;
    padding-left: $spacing--tiny;
    width: 100%;
    border: 1px solid transparent;

    span,
    input {
      border: none;
      outline: none;
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    input.editing {
      background: transparent;
    }

    a {
      color: $color-gray--light;
      text-decoration: underline;
    }
  }

  &-checkbox {
    &,
    &__icon {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  &-checkbox__icon {
    fill: $color-gray;

    &:hover {
      fill: $color-brand-blue;
    }
  }

  &__draggable-wrapper {
    outline: none;

    &[aria-grabbed='true'] {
      background-color: $color-white;
      box-shadow: 0 0 0.25rem $color-gray--light;
    }
  }

  &-button {
    padding: $spacing--tiny 0;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .waiting-spinner {
    display: inline-block;
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 0.5em;
    margin-top: 0.05em;
    background-size: contain;
  }
}

/* stylelint-enable no-descending-specificity */
