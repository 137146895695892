.u-position-relative {
  position: relative;
}

.u-sticky-top-padded {
  position: sticky;
  position: -webkit-sticky; /* stylelint-disable-line value-no-vendor-prefix */
  top: 1rem;
}

.u-float-none {
  float: none !important; /* stylelint-disable-line declaration-no-important */
}

.u-float-left {
  float: left !important; /* stylelint-disable-line declaration-no-important */
}

.u-no-margin-bottom {
  margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.u-no-padding-bottom {
  padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.u-no-padding {
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.u-margin-bottom {
  margin-bottom: 1rem;
}

.u-no-margin {
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.u-allow-pointer-events {
  pointer-events: inherit !important; /* stylelint-disable-line declaration-no-important */
}

.u-display-none {
  display: none;
}

.u-display-block {
  display: block;
}

.vertical-align-middle,
.v-align-middle {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;

  & > :first-child {
    width: 100%;
  }
}

.p-relative {
  position: relative;
}

.monospace {
  font-family: monospace;
  font-weight: bold;
}

// Legacy utilities below:

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.u-white-space-normal {
  white-space: normal;
}

.width-fit-content {
  width: fit-content;
}

.height-fit-content {
  height: fit-content;
}
