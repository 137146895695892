@import 'styles/variables';

.tabsNavigation {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
}

.tabsNavigationList {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
}

.tabsNavigationItem {
  font-weight: $font-weight--light;
  position: relative;
  margin-bottom: 0.5rem;
  font-size: $font-size--base;
  flex: 0 0 auto;

  &:not(:last-of-type) {
    margin-right: 0.8rem;
  }

  svg {
    width: 75%;
    margin-left: 0;
  }

  span {
    vertical-align: top;
  }

  a {
    color: $color-text; /* stylelint-disable-line declaration-no-important */
    display: block;
    text-align: center;

    // prevents tabs shifting when selected
    &::before {
      display: block;
      content: attr(title);
      font-weight: $font-weight--default;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  &:not(:first-of-type) a {
    text-align: center;
  }
}

.tabsNavigationActiveItem,
.tabsNavigationItem:hover a {
  &::after {
    content: '';
    width: 70%;
    display: block;
    margin: auto;
    border-width: 1px;
    border-style: solid;
  }
}

.tabsNavigationActiveItem::after {
  border-color: var(--theme-primary) !important; /* stylelint-disable-line declaration-no-important */
}

.tabsNavigationItem:hover a::after {
  border-color: $color-border;
}

.tabsNavigationActiveItem {
  color: var(--theme-primary) !important; /* stylelint-disable-line declaration-no-important */
  font-weight: bold;
  display: block;
}

.tabsNavigationActiveItemColorless {
  @extend .tabsNavigationActiveItem;

  color: $color-text !important; /* stylelint-disable-line declaration-no-important */
}
