@import 'styles/variables';

.container {
  display: inline-block;

  svg {
    fill: #fff;
    margin-left: $spacing--tiny;
  }
}

.tooltipInner {
  background-color: #c1e3f0;
  border: 1px solid $color-link;
  color: #222;
  max-width: 30vw;

  p {
    margin: $spacing--default 0 0;
  }

  a {
    color: $color-link;
    text-decoration: none;

    &:hover {
      color: $color-link-dark;
    }
  }
}

.premiumContent {
  color: $color-brand-black--highlight;
  letter-spacing: 0.01rem;
  margin-bottom: $spacing--default;
  font-weight: $font-weight--default;
  text-transform: uppercase;

  svg {
    fill: $color-brand-black--highlight;
    height: $font-size--large;
    margin-right: $spacing--small;
    display: inline-block;
  }
}

.icon svg {
  height: $font-size--large;
}

.darkIcon svg {
  fill: $color-brand-black--highlight;
}

label ~ .container {
  float: right;
  margin-right: 2rem;
}

label ~ .container .icon {
  color: $color-gray;
}

label ~ .container .icon svg {
  fill: $color-gray;
  height: $font-size--medium;
  vertical-align: middle;
}

.header {
  font-size: $font-size--medium;
  font-weight: $font-weight--medium;
}
