@import 'styles/variables';

.suggestionContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid $color-brand-blue;
  border-radius: 0 0 $border-radius-lg $border-radius-lg;
  text-transform: none;
  width: 100%;
  margin: -3px 0 0; /* stylelint-disable-line declaration-property-unit-disallowed-list */
  background-color: $color-brand-blue;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  /* stylelint-disable-next-line declaration-property-unit-disallowed-list */
  box-shadow: 0 3px 5px 2px rgba(0 0 0 / 10%);
  padding: 0 $spacing--small;
  min-height: 2.9rem;

  @media only screen and (max-width: 586px) {
    padding: 0 0 $spacing--small;
    flex-direction: column;
  }
}

.suggestionLabel {
  display: flex;
  color: $color-white;
  background-color: $color-dark-blue;
  font-weight: $font-weight--medium;
  //padding: $spacing--tiny $spacing--small;
  //border-radius: $border-radius-xs;
  border-radius: 0 0 0 $border-radius-xs;

  @media only screen and (max-width: 586px) {
    padding: $spacing--xtiny $spacing--default;
    width: 100%;
  }

  @media only screen and (min-width: 587px) {
    margin: -1rem -0.5rem;
    height: max-content;
    padding: $spacing--default;
  }

  svg {
    fill: $color-white;
    width: 1rem;
    margin-right: $spacing--small;
  }
}

.suggestion {
  color: $color-white;
  font-weight: $font-weight--heavy;
  margin-left: $spacing--small;
  margin-right: $spacing--small;
  width: 100%;
  padding: $spacing--default;
}

.buttonContainer {
  display: flex;
  height: max-content;
}

.suggestionButton {
  composes: btn btn-secondary btn-sm from global;
  margin-left: $spacing--tiny;
}
