.thinking {
  position: relative;

  span {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: waviy;
    display: inline-block;
    padding: 0 0.25rem;
    position: relative;

    &.first {
      animation-delay: calc(0.1s);
    }

    &.second {
      animation-delay: calc(0.2s);
    }

    &.third {
      animation-delay: calc(0.3s);
    }
  }

  @keyframes waviy {
    0%,
    40%,
    100% {
      transform: translateY(0);
    }

    20% {
      transform: translateY(-0.5rem);
    }
  }
}
