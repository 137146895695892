// Please don't change any measurements to rem
// Seems like bootstrap tooltip doesn't deal well with rem values

/* stylelint-disable declaration-property-unit-disallowed-list */

.ui-definition-tooltip {
  display: flex;
  align-items: center;

  &__title {
    margin-right: 8px;

    h3 {
      position: static;
    }
  }

  &__question-badge {
    display: flex;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: $font-size--large;
    background-color: $color-gray--light;
    color: $color-white;
    border-radius: 100%;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-around;
    opacity: 0.7;
    margin-bottom: 0.3rem;

    svg {
      fill: $color-white;
      width: 12px;
      height: 12px;
    }

    &:hover {
      background-color: $color-tooltip-background;
      opacity: 1;
    }
  }
}

/* stylelint-enable declaration-property-unit-disallowed-list */
