@import 'styles/variables';

.headerContent {
  align-items: center;
  display: flex;

  :global {
    h1,
    h2,
    h3,
    h4 {
      margin-bottom: 0;
    }
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &.withBottomMargin {
    margin-bottom: $spacing--tiny;
  }
}

.buttonsContainer {
  display: flex;
}

.button {
  margin: auto $spacing--tiny;
}

.editButton {
  margin: 0 $spacing--xtiny 0 $spacing--xtiny;
}

.iconCircle,
.iconFrogbot {
  border-radius: 50%;
  display: flex;
  padding: 0.125rem;
}

.iconCircle {
  background-color: $color-gray--light;

  svg {
    fill: $color-white;
    width: 1rem;
    height: 1rem;
  }
}

.iconFrogbot {
  background-color: $color-white;
  border: 2px solid $color-gray--light;

  svg {
    fill: $color-gray--light;
    width: 1rem;
    height: 1rem;
  }
}

.premiumCalloutIcon {
  position: relative;
  height: 2.75rem;
  width: 2.75rem;

  svg {
    width: 1rem;
    position: absolute;
    fill: $color-gray--light;
  }

  > svg {
    bottom: 0.72rem;
    left: 0.62rem;
  }

  span svg {
    top: 0.25rem;
    right: 0.525rem;
  }
}

.helpButton {
  margin-left: $spacing--tiny;
  background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
  padding: $spacing--default;

  &Active,
  &:focus,
  &:hover {
    .iconCircle {
      background-color: $color-brand-black--highlight;
    }

    .iconFrogbot {
      border-color: $color-brand-black--highlight;

      svg {
        fill: $color-brand-black--highlight;
      }
    }
  }
}

.divider {
  background-color: $color-gray--lighter;
  width: 100%;
  height: 1px; /* stylelint-disable-line declaration-property-unit-disallowed-list */
}

.tipContainer {
  display: flex;
  justify-content: space-between;
}

.closeContainer {
  display: flex;
  margin-top: -0.5rem;
}

.farRightSectionContainer {
  flex: 1;
  text-align: end;
  padding-right: 2rem;
}
