@import 'styles/variables';

.visibilityContainer {
  display: inline-block;
  margin-left: $spacing--small;
  font-size: $font-size--small;
  padding: 0 $spacing--small;
  color: $color-text--gray;
}

.privateIcon {
  height: 0.7rem;
  width: 0.7rem;
  margin-right: $spacing--tiny;
  fill: $color-gray;
}
