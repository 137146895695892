.proposal-diff-component {
  white-space: pre-wrap;

  ul {
    list-style-type: disc;
    padding-left: 1rem;
  }

  ol {
    list-style-position: inside;
    white-space: normal;
  }

  &__old-value,
  del,
  del > * {
    text-decoration: line-through;
    background-color: $color-proposal-removing-red--background;
    margin-right: 0.3rem;
  }

  &__new-value,
  ins,
  ins > * {
    text-decoration: underline;
    background-color: $color-proposal-adding-green--background;
  }

  s {
    text-decoration: line-through;
  }
}
