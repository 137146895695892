@import 'styles/variables';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.removedItem {
  box-shadow: inset 0.187rem 0 0 0 $color-red;
  text-decoration: line-through;
}

.changedItem {
  background-color: $color-proposal-input-changed;
}

.removeButtonContainer {
  margin-left: 1rem;
  margin-bottom: 1.25rem; // should be consistent with app/javascript/components/ui/forms/InputContainer/index.scss
  display: flex;
  align-items: center;
}

.removeButtonPlaceholder {
  composes: removeButtonContainer;
  width: 2rem;
}

.removeButton {
  border: none;
  background: transparent;
  display: flex;
  height: 1.75rem;
  width: 1.75rem;
  justify-content: center;
  align-items: center;

  svg {
    fill: $color-red;
  }

  &:focus,
  &:hover {
    background-color: $color-gray--lighter;
    box-shadow: none;

    svg {
      fill: $color-link;
    }
  }
}

.removeIcon {
  height: 1.125rem;
  width: 1.125rem;
}
