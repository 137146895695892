@import 'styles/variables';

.container {
  margin-bottom: $spacing--medium;
}

.subHeader {
  font-weight: $font-weight--medium;
  margin-bottom: $spacing--small;
  margin-top: $spacing--medium;
}
