.btn-header-group {
  .btn-header-group__item {
    display: inline-block;
  }

  .btn-header-group__item + .btn-header-group__item {
    margin-left: $spacing--small;
  }
}

.saml-settings-form__container {
  .form-check {
    justify-content: space-between;
    flex-wrap: wrap;

    .form-text:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
