@import 'styles/variables';

.chatContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 500;

  @media only screen and (max-width: 767px) {
    bottom: $spacing--medium;
  }
}

.openChatButton {
  width: 4rem;
  height: 4rem;
  border: 3px solid var(--theme-primary);
  border-radius: 50%;
  margin: 1rem;
  padding: 0.5rem;
  background: $color-gray--white-smoke;

  &:hover {
    cursor: pointer;
    background: $color-gray--lighter;
  }

  svg {
    fill: var(--theme-primary);
  }
}
