@import 'styles/variables';

.container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -0.5rem;
}

// damn bootstrap!
.dropdownButton {
  padding: $spacing--tiny 0;
  display: flex !important; /* stylelint-disable-line declaration-no-important */
  align-items: center;
  color: $color-text !important; /* stylelint-disable-line declaration-no-important */
  font-size: $font-size--body !important; /* stylelint-disable-line declaration-no-important */
  font-weight: $font-weight--light !important; /* stylelint-disable-line declaration-no-important */

  .highlightedLabel {
    color: $color-link;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    color: $color-text;
    text-decoration: none;

    .highlightedLabel {
      color: $color-brand-blue-darker;
      text-decoration: underline;
    }
  }
}

.dropdownMenu {
  margin: 0;
  padding: 0;
  min-width: 12.5rem !important; /* stylelint-disable-line declaration-no-important */
  border-radius: $border-radius-lg;
  box-shadow: 0 0 0.5rem 0.02rem $color-gray--lightest;
}
