@import 'styles/variables';

.alerts {
  z-index: 2000; // seems to be enough to overflow modals
  padding: $spacing--medium;
  width: 100%;
  max-width: 60rem;
}

.top {
  position: absolute;
  top: 0;
}

.bottom {
  position: fixed;
  bottom: 0;
  margin-bottom: -1rem;
  width: max(35rem, 100% - 22rem);
}
