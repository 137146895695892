@import 'styles/variables';

.suggestionSection {
  width: 100%;
}

.arrow {
  grid-area: arrow;
  font-size: $font-size--larger;
  color: $color-gray--lighter;
  text-align: center;
}

.actions {
  grid-area: actions;
  padding-left: $spacing--medium;
  padding-right: $spacing--medium;
  font-style: italic;
}

.action {
  padding: $spacing--tiny 0;
  width: 100%;
}

.button {
  width: 100%;
  text-align: center;
}

.actionLink {
  composes: btn btn-primary from global;
  composes: button;
  font-style: normal;
}

.suggestions {
  grid-area: suggestions;
}

.suggestion {
  list-style-type: none;
  list-style-position: inside;
  padding: $spacing--small;
}

.suggestions,
.actions {
  font-size: $font-size--button;

  h3 {
    font-size: $font-size--body;
    font-weight: $font-weight--heavy;
  }

  br {
    line-height: $spacing--tiny;
  }
}

.suggestionRow {
  padding-top: $spacing--small;
  padding-bottom: $spacing--small;
  display: grid;
  align-items: center;
  grid-template: 'suggestions arrow actions' / 1fr minmax(1.5rem, 10%) minmax(20rem, 30%);

  &Small {
    grid-template:
      'suggestions'
      'actions';

    .arrow {
      display: none;
    }

    .actions {
      padding-left: 0;
      padding-right: 0;
    }

    .suggestion {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.hint {
  text-indent: $spacing--tiny;
}

.doneHint {
  color: $color-text--gray;
  font-size: $font-size--button-sm;
}

.buttonIcon {
  padding-right: $spacing--small;
}
