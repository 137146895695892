@import 'styles/variables';

.iconButton {
  composes: sidebar-icon-button from global;
}

.xs {
  height: 1.5rem;
  width: 1.5rem;
}

.sm {
  height: 1.75rem;
  width: 1.75rem;
}

.md {
  height: 2rem;
  width: 2rem;
}

.lg {
  height: 2.75rem;
  width: 2.75rem;
}

.light {
  background: var(--theme-primary);

  &:hover,
  &:focus {
    background: var(--theme-primary-highlighted);
  }
}

.darkBlue {
  background-color: $color-dark-blue;

  &:hover,
  &:focus {
    background: $color-dark-blue--hover;
  }
}

.dark {
  background-color: $color-brand-black;

  &:hover,
  &:focus {
    background-color: $color-brand-black--hover;
  }
}

.tensionIconButton {
  svg {
    fill: $color-brand-blue;
    stroke: $color-brand-blue;
  }
}

.darkTensionIconButton {
  svg {
    stroke: $color-brand-black;
    fill: $color-brand-black;
  }
}

.tensionIconButton,
.darkTensionIconButton {
  svg {
    width: 0.75rem;
    height: 0.75rem;
    stroke-width: 1rem;
  }
}
