// from /app/assets/stylesheets/vendor/bootstrap/scss/_button-group.scss
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .ui-button:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-radius: 0.2rem;
  border-bottom-left-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
  border-top-left-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.btn-group > .btn:first-child:not(:last-child, .dropdown-toggle),
.btn-group > .ui-button:first-child:not(:last-child, .dropdown-toggle) {
  border-radius: 0.2rem;
  border-bottom-right-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
  border-top-right-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
}

// from /app/assets/stylesheets/vendor/bootstrap/scss/_dropdown.scss
.dropdown-item {
  text-align: left;
  font-weight: $font-weight--light !important; /* stylelint-disable-line declaration-no-important */
}

.dropdown-toggle::after {
  margin-left: 0.3rem;
}

// from /app/assets/stylesheets/vendor/bootstrap/scss/_forms.scss
// the Bootstrap version seems old-school, so using updated styling
.form-check {
  margin: 0 0 1.2rem;
  padding: 0;
  display: flex;
  align-items: center;

  .form-check-label,
  .form-check-input {
    position: relative;
    padding: 0;
    margin: 0;
  }

  .form-check-input,
  input[type='checkbox'],
  input[type='radio'] {
    margin-right: 0.5rem;
  }
}

// from /app/assets/stylesheets/vendor/bootstrap/scss/_input-group.scss
.form-control:disabled,
.form-control[readonly] {
  background-color: $color-gray--lightest;
  opacity: 1;
}

.input-group .form-control:not(:last-child) {
  border-bottom-right-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
  border-top-right-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.input-group-addon {
  border-radius: 0.2rem;
  border-bottom-left-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
  border-top-left-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0 !important; /* stylelint-disable-line declaration-no-important */
  border-right: 1px solid $color-border !important; /* stylelint-disable-line declaration-no-important */
}

// from /Users/lex/dev/glassfrog/app/assets/stylesheets/vendor/bootstrap/scss/_modal.scss
.modal-footer {
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items

  // Easily place margin between footer elements
  > :not(:first-child) { margin-left: 0.25rem; }
  > :not(:last-child) { margin-right: 0.25rem; }
}

// from /app/assets/stylesheets/vendor/bootstrap/scss/_reboot.scss
th {
  text-align: left;
}

// from app/assets/stylesheets/vendor/bootstrap/dist/css/bootstrap.css
.float-right {
  float: right !important; /* stylelint-disable-line declaration-no-important */
}

.float-left {
  float: left !important; /* stylelint-disable-line declaration-no-important */
}

.text-left {
  text-align: left !important; /* stylelint-disable-line declaration-no-important */
}

.text-right {
  text-align: right !important; /* stylelint-disable-line declaration-no-important */
}

.m-0 {
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.mr-0 {
  margin-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.ml-0 {
  margin-left: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.mx-0 {
  margin-right: 0 !important; /* stylelint-disable-line declaration-no-important */
  margin-left: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.m-1 {
  margin: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-1 {
  margin-right: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-1 {
  margin-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-1 {
  margin-right: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
  margin-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-2 {
  margin: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-2 {
  margin-right: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-2 {
  margin-left: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-2 {
  margin-right: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-left: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-3 {
  margin: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-3 {
  margin-right: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-3 {
  margin-left: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-3 {
  margin-right: 1rem !important; /* stylelint-disable-line declaration-no-important */
  margin-left: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-4 {
  margin: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-4 {
  margin-right: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-4 {
  margin-left: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-4 {
  margin-right: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
  margin-left: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.m-5 {
  margin: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.mr-5 {
  margin-right: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-5 {
  margin-left: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.mx-5 {
  margin-right: 3rem !important; /* stylelint-disable-line declaration-no-important */
  margin-left: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.ml-auto,
.mx-auto {
  margin-left: auto !important; /* stylelint-disable-line declaration-no-important */
  margin-right: unset !important; /* stylelint-disable-line declaration-no-important */
}

.mr-auto,
.mx-auto {
  margin-left: unset !important; /* stylelint-disable-line declaration-no-important */
  margin-right: auto !important; /* stylelint-disable-line declaration-no-important */
}

.p-0 {
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.pt-0 {
  padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.pr-0 {
  padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.pl-0 {
  padding-left: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.px-0 {
  padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.p-1 {
  padding: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-1 {
  padding-right: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-1 {
  padding-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-1 {
  padding-right: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 0.25rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-2 {
  padding: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-2 {
  padding-right: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-2 {
  padding-left: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-2 {
  padding-right: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-3 {
  padding: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-3 {
  padding-right: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-3 {
  padding-left: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-3 {
  padding-right: 1rem !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-4 {
  padding: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-4 {
  padding-right: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-4 {
  padding-left: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-4 {
  padding-right: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 1.5rem !important; /* stylelint-disable-line declaration-no-important */
}

.p-5 {
  padding: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.pr-5 {
  padding-right: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.pl-5 {
  padding-left: 3rem !important; /* stylelint-disable-line declaration-no-important */
}

.px-5 {
  padding-right: 3rem !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 3rem !important; /* stylelint-disable-line declaration-no-important */
}
