@import 'styles/variables';

$one-pixel: 0.0625rem;

.classicCssVersion {
  .container {
    margin-left: -$spacing--small;
    padding: $spacing--medium $spacing--small;
    margin-right: -$spacing--small;
    border-radius: $border-radius;
    transition: all 0.3s ease-in-out;
  }

  .clickableContainer {
    cursor: pointer;
  }

  .additionalSection {
    margin-bottom: $spacing--small;
    margin-top: -$spacing--default;
  }
}

.workspaceCssVersion {
  .additionalSection {
    margin-bottom: $spacing--small;
  }
}
