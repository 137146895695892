@import '../../../assets/stylesheets/vendor/bootstrap/scss/mixins/clearfix';

/* stylelint-disable no-descending-specificity */
.box {
  background: $color-white;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  margin-bottom: 1.25rem;

  .box__body {
    padding: 1rem;

    a {
      hyphens: auto;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    .btn {
      margin-left: 0.625rem;
    }

    .box__footer {
      margin: 0 -1rem -1rem;
    }
  }

  .box__header {
    background: $color-background-gray--light;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    padding: 0.75rem 1rem;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }

    h3,
    h4 {
      display: inline;
      font-size: $font-size--body;
      margin-bottom: 0;
    }

    + .item {
      border-top: none;
    }

    .controls {
      display: inline-block;
      float: right;
      font-size: 1rem;
      margin-top: -$spacing--xtiny;

      a {
        color: $color-gray--lighter;
      }
    }

    a:not(.btn) {
      color: darken($color-brand-blue, 20%);
    }

    &:hover {
      .controls a {
        color: $color-brand-blue;
      }
    }

    i {
      margin-right: $spacing--small;
    }
  }

  .box__header--no-body {
    border-radius: $border-radius-lg;
  }

  .box__footer {
    @include clearfix;

    background: $color-background-gray--light;
    border-radius: 0 0 $border-radius $border-radius;
    border-top: 1px solid $color-border;
    padding: 1rem;

    .button {
      margin-bottom: 0;
    }
  }

  li.edit-mode,
  .item.edit-mode {
    background: $color-gray--lightest;
  }

  .list.list--divided {
    box-shadow: none;
    border: none;
    margin-bottom: 0;
  }
}

.box.box--split {
  display: flex;

  .box__header {
    border-radius: 0;
    border-bottom-left-radius: $border-radius-lg;
    border-top-left-radius: $border-radius-lg;
    border: none;
    border-right: 1px solid $color-border;
    flex: 0 0 auto;
    min-height: 6.25rem;
    padding: 1rem;
    width: 25%;

    .box__header__title {
      display: block;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  .box__body {
    flex: 3 1 auto;
  }
}

.box.box--split--large {
  .box__header {
    width: 33.33%;
  }
}

.box--simple {
  background: $color-white;

  .box__header {
    background: none;
    border-bottom: none;
    padding: 1.25rem 1.25rem 0;
  }

  .box__body {
    padding: 1.25rem;

    p,
    &__paragraph {
      font-size: 1rem;
      line-height: 1.7;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .box__footer {
      margin: 0 -1.25rem -1.25rem;
    }
  }

  .box__header + .box__body {
    padding-top: 0;
  }
}

.box--list {
  padding: 0;

  li,
  .item {
    border-top: 1px solid $color-border;
    display: flex;
    padding: 1rem;

    &:first-child {
      border-top: none;
    }

    &:hover {
      background: $color-list-item-hover;
    }
  }
}

.box--list-custom,
.box--list-grid {
  padding: 0;

  .list-item {
    border-top: 1px solid $color-border;
    display: block;
    padding: 1rem;

    &:hover {
      background: $color-list-item-hover;
    }
  }

  li:first-child {
    .list-item {
      border-top: none;
    }
  }
}

.box--list-grid {
  .list-item {
    border-right: 1px solid $color-border;
  }
}

.box--flat {
  background: $color-background-gray--light;
  border: 1px solid $color-border;
}

/* stylelint-enable no-descending-specificity */
