.exportButton {
  composes: btn from global;
  composes: btn-primary from global;
  composes: btn-block from global;
  margin-left: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.buttonInner {
  display: inline-flex;
}

.buttonText {
  margin-right: 0.75rem;
}
