@import 'styles/variables';

.container {
  margin-bottom: 0;
  display: flex;
  height: 1.75rem;
  width: 1.75rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  transition: background-color, fill, 150ms;

  &:focus-within,
  &:hover {
    background-color: $color-gray--lighter;

    svg {
      fill: $color-link;
    }
  }
}

.icon {
  height: 1.125rem;
  width: 1.125rem;
}

.rollbackIcon {
  composes: icon;
  fill: $color-gray;
}

.deleteIcon {
  composes: icon;
  fill: $color-red;
}
