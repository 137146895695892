@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $spacing--tiny;
}

.status {
  margin-left: $spacing--default;
}

.description {
  p {
    margin-bottom: 0;
  }
}
