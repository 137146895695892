.proposal-summary-move-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  @media (max-width: $width-sm) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }

  // IE code goes there
  @supports not(grid-gap: 1rem) {
    display: flex;
    flex-direction: column;

    .proposal-summary-list {
      margin-bottom: 1rem;
    }
  }
}
