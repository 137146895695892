@import 'styles/variables';

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: $spacing--huge;
}

.leftBlock {
  display: flex;
  flex-wrap: wrap;

  button {
    margin: 0;
  }
}

.rightBlock {
  composes: leftBlock;
  justify-content: flex-end;

  button {
    margin-right: $spacing--small;
  }
}
