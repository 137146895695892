@import 'styles/variables';

.wrapper {
  composes: list-view-note-tooltip__wrapper from global;
  display: inline-block;
}

.iconContainer {
  composes: list-view-note-tooltip from global;
  align-items: flex-start;
  display: flex;

  svg {
    width: 0.5rem;
    fill: $color-gray;
  }
}

.tooltip {
  white-space: pre-line;
}
