@import 'styles/variables';

.placeholder {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1rem 0.75rem;
  margin: 0 -1rem;

  > div {
    position: relative;
    background-color: $color-white;
    animation-name: loading;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    opacity: 0.5;
  }
}

.orgName {
  height: 1.6875rem;
  width: 75%;
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
}

.userName {
  height: 0.875rem;
  width: 25%;
  border-radius: 0.25rem;
}

@keyframes loading {
  0% {
    transition: none;
  }

  33% {
    background-color: transparent;
    transition: all 2s ease-out;
  }

  66% {
    transition: none;
  }
}
