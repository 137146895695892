input.error,
.fieldWithErrors input,
.field_with_errors input {
  box-shadow: 0 0 0.25rem $color-orange;
  border-color: $color-orange;
}

label.error {
  margin-top: -0.5rem;
  color: $color-white;
  background-color: $color-orange;
  border: 0;
  position: absolute;
  font-size: $font-size--small;
  font-family: Verdana, Geneva, sans-serif;
  padding: 0.125rem 0.5rem;
  display: block;
  white-space: nowrap;
  border-radius: 0.25rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgb(37 46 46 / 26%);
  z-index: 10;
}

label.error::before {
  content: '';
  position: absolute;
  left: 0.5rem;
  top: -0.25rem;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid $color-orange;
}

.error-container {
  position: relative;
  margin: 0 0 0.5rem;
  color: $color-red;
}

.field_with_errors .error-container::after {
  content: '⚠';
  display: inline-block;
  font-size: 1.5rem;
  padding: 0.25rem;
  vertical-align: middle;
  color: $color-orange;
}

.field_with_errors {
  white-space: nowrap;
}

#errorExplanation {
  width: 25rem;
  padding: 0.5rem;
  margin-bottom: 1.25rem;
  background-color: $color-orange;
  color: $color-white;
}

#errorExplanation.wideError {
  width: 100%;
}

#errorExplanation h2 {
  text-align: left;
  font-weight: bold;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  font-size: $font-size--small;
  margin: -0.5rem;
  background-color: $color-orange;
  color: $color-white;
}

#errorExplanation h2,
#errorExplanation p {
  display: none;
}

#errorExplanation p {
  color: $color-text;
  margin-bottom: 0;
  padding: 0.25rem;
}

#errorExplanation ul {
  padding-left: 1.375rem;
}

#errorExplanation ul li {
  font-size: $font-size--body;
  list-style: square;
}

#errorExplanation.wideError ul li {
  list-style: none;
}

/* Devise Overrides */
#error_explanation h2 {
  display: none;
}
