@import 'styles/variables';

.box {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}
