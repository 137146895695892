@import 'styles/variables';

.itemDetailRow {
  font-size: $font-size--small;
  color: $color-text--gray;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.separatorDot {
  padding: 0 $spacing--small;
}
