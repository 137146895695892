.refinery-toolbar {
  display: flex;

  .ui-icon-button {
    height: 1.5rem;
    width: 1.5rem;
  }

  &__summary-toggle {
    display: flex;

    .refinery-toolbar__left-button {
      .ui-icon-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .refinery-toolbar__right-button {
      .ui-icon-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__delete-button {
    margin-left: 0.7rem;
  }
}
