.circle-footer-keep-delete-switch {
  display: flex;
  flex-direction: column;

  &__toggle {
    input[type='radio'] {
      margin-right: 0.5rem;
    }
  }
}
