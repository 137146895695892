// This class is for displaying user content with the WYSIWYG feature flag off.
@import './app/assets/stylesheets/components/_typography.scss';

/* stylelint-disable no-descending-specificity */
.main.wysiwyg-disabled .user-content {
  h3 {
    @extend .heading--generic;
  }

  a {
    hyphens: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  p {
    margin-bottom: 0.5rem;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  pre {
    white-space: pre-wrap;
  }

  // here we're trying to match the padding/alignment of our bulleted lists when users
  // put in <li> without surrounding <ul>
  li {
    list-style-type: none;
    margin-left: 0.85rem !important; /* stylelint-disable-line declaration-no-important */
    position: relative;

    &::before {
      content: '\2022';
      font-size: 1.25rem;
      display: inline-block;
      width: 1rem;
      margin-left: -1rem;
      line-height: 1rem;
      position: absolute;
      top: 0.15rem;
    }
  }

  ul,
  ol {
    margin: 0 0 0 0.75rem !important; /* stylelint-disable-line declaration-no-important */
    padding: 0 !important; /* stylelint-disable-line declaration-no-important */

    &:not(:last-child) {
      margin-bottom: $spacing--medium !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  ol {
    counter-reset: item;

    li {
      &::before {
        counter-increment: item;
        content: counter(item) '.';
        font-size: inherit;
      }
    }
  }
}

/* stylelint-enable no-descending-specificity */
