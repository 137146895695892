@import 'styles/variables';

.grid {
  display: grid;
  grid-template-areas:
    'icon label'
    '. description';
  grid-gap: 0.25rem;
  align-items: center;
  justify-content: left;
  grid-template-columns: auto 100%;
  margin-left: 0;
  margin-top: $spacing--medium;
  width: 100%;
  position: relative;
}

.label,
.icon {
  display: none;
}

.withPremiumCallout {
  display: inline-flex !important; /* stylelint-disable-line declaration-no-important */
  opacity: 0.6;
}

.description {
  grid-area: description;
  transition: all 1s;

  &.collapsed {
    margin-bottom: 3rem;
    mask-image: linear-gradient(rgb(0 0 0), calc(100% - 2rem), transparent);
    max-height: 12rem;
    overflow: hidden;
  }

  &.expanded {
    margin-bottom: 3rem;
    mask-image: linear-gradient(rgb(0 0 0));
    max-height: 100rem;
    overflow: hidden;
  }
}

input ~ span .grid {
  width: 98%;

  .description {
    margin: 1rem 0 0 -1.5rem;
  }
}

.icon {
  grid-area: icon;

  svg {
    fill: $color-brand-black--highlight;
    width: $font-size--medium;
    height: $font-size--medium;
    display: block;
  }
}

.label {
  grid-area: label;
  font-size: $font-size--medium;
  font-weight: $font-weight--default;
}

input ~ span span .label,
input ~ span span .icon {
  display: inherit;
}

.showMoreLess {
  color: $color-brand-blue-darker;
  cursor: pointer;
  position: absolute;
  text-transform: lowercase;
  display: block;
  background: rgba(16 156 174 / 10%);
  width: 100%;
  left: 0;
  bottom: 1.1rem;
  padding: 0 0.5rem;
}
