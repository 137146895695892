@import 'styles/variables';

.input {
  padding: $spacing--small;
  background: #ddd;
  border-top: 1px solid #ccc;
  border-radius: 0 0 0.2rem 0.2rem;

  input {
    width: calc(100% - 3.5rem);
    height: 2rem;
    padding: $spacing--tiny $spacing--small;
    font-weight: normal;
    margin-right: 0.5rem;
    border-radius: 0.2rem;
    outline: none;
    border: 1px solid #ccc;
    background: $color-white;
  }

  button {
    width: 3rem;
    height: 2rem;
    background: $color-brand-blue;
    color: $color-white;
    border-radius: 0.2rem;
    border: 0;
  }
}

.debugModeToggle {
  width: 10rem;
  opacity: 0.7;

  label input[type='checkbox'] ~ div {
    color: $color-text--gray;
  }

  &:hover,
  &.debugSelected {
    opacity: 1;
  }
}
