@import 'styles/variables';

.loadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loadingDot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: $color-white;
  animation: loading-dots-keyframes 1.2s infinite ease-in-out both;
  border-radius: 50%;
}

.loadingDotSecondary {
  background-color: $color-brand-blue;
}

@keyframes loading-dots-keyframes {
  50% { background-color: transparent; }
}
