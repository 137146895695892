@import 'styles/variables';

.reorderControl {
  margin-right: 0.5rem;
  margin-top: 0.1rem;
  padding: $spacing--tiny;
}

.descriptionContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.sourceContainer {
  margin-left: $spacing--small;
  color: $color-text--gray;
  white-space: nowrap;
}

.sourceIcon {
  width: 0.7rem;
  height: 0.7rem;
  margin-right: $spacing--tiny;
  fill: $color-text--gray;
}

.controlButtons {
  display: flex;
  flex-flow: row nowrap;
}
