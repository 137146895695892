@import 'styles/variables';

.startProposalBtn {
  composes: btn btn-primary btn-small from global;
  display: flex;
  align-items: center;

  svg {
    height: 1em;
    fill: $color-white;
    margin-left: $spacing--default;
  }
}
