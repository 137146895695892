@import 'styles/variables';

.content {
  margin-top: $spacing--medium;
  composes: user-content from global;

  li {
    margin-left: $spacing--large !important; /* stylelint-disable-line declaration-no-important */
  }
}

.noMargin {
  margin: 0;
}
