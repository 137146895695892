@import 'styles/variables';

.icon {
  transition: fill 0.3s ease-in-out;
  fill: $color-gray;
}

.container {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    .icon {
      fill: $color-brand-blue--highlight;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.125rem $color-brand-blue--clicked;

    .icon {
      fill: $color-brand-blue--clicked;
    }
  }
}
