@import 'styles/variables';

.badge {
  padding: $spacing--tiny $spacing--small;
  line-height: $font-size--small;
  font-size: $font-size--small;
  color: $color-white;
  border-radius: $border-radius;
}

.current {
  background-color: var(--theme-success);
  border-color: var(--theme-success);
}

.future {
  background-color: $color-status-future;
  border-color: $color-status-future;
}

.done {
  background-color: var(--theme-info);
  border-color: var(--theme-info);
}

.waiting {
  background-color: var(--theme-warning);
  border-color: var(--theme-warning);
}

.info {
  background-color: $color-brand-blue;
  border-color: $color-brand-blue;
}
