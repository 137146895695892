@import 'styles/variables';

.container {
  display: flex;
}

.cite {
  margin-top: $spacing--small;
  font-size: $font-size--base;
}
