/* stylelint-disable no-descending-specificity */

.header_group {
  padding: 0 0.75rem;
  width: 100%;
}

.header_group--inner {
  text-align: center;
  border-bottom: 1px solid $color-border;
}

table.holacracy-stats {
  .header_group--inner {
    width: 16rem;
  }
}

// Can  we get rid of this inner-grid thing that's on a couple of pages?
table.inner-grid {
  border-collapse: collapse;
}

table.inner-grid td,
table.inner-grid th {
  border: 1px solid $color-border;
}

table.inner-grid tr:first-child th {
  border-top: 0;
}

table.inner-grid tr:last-child td {
  border-bottom: 0;
}

table.inner-grid tr td:first-child,
table.inner-grid tr th:first-child {
  border-left: 0;
}

table.inner-grid tr td:last-child,
table.inner-grid tr th:last-child {
  border-right: 0;
}

table.interactive:not(.by-cell) tr:hover,
table.interactive.by-cell td:hover,
.table.interactive:not(.by-cell) > .tr:hover,
.tbody.interactive:not(.by-cell) > .tr:hover,
dl.interactive dt:hover,
dl.interactive dd:hover,
div.interactive:hover,
ul.interactive li:hover,
li.interactive:hover,
.interactive-hovered,
.zebra .stripe:nth-of-type(odd).interactive-hovered,
.zebra .stripe:nth-of-type(even).interactive-hovered,
.zebra .interactive.stripe:hover {
  background-color: $color-list-item-hover;
}

table.no-padding td {
  padding: 0;
}

table.zebra {
  td {
    padding: 0.35rem 0.5rem;
  }
}

table.interactive tr {
  height: 2.25rem;
}

table tr.footer {
  border-top: 1px solid $color-border;
}

tr.footer > td {
  padding: 0.5rem 0 !important; /* stylelint-disable-line declaration-no-important */
}

table.interactive tr.footer:hover,
table.interactive tr.theader {
  background-color: transparent;
}

table.interactive th:hover {
  background-color: $color-white !important; /* stylelint-disable-line declaration-no-important */
}

table.manycolumns {
  border-collapse: collapse;
  background: $color-white;
  vertical-align: top;
}

table th.date-col {
  width: 11rem;
}

table.manycolumns th {
  padding: 0 0.35rem;
}

// TODO: Refactor tables to use flex?
table td,
.table .td {
  padding: 0 0.5rem;
}

.table {
  display: table;
}

.table .tbody,
.table .tfoot {
  display: table-row-group;
}

.table .row-group {
  display: table-row-group;
}

.table .tr {
  display: table-row;
}

.table .th {
  display: table-cell;
  padding: 0.5rem;
}

table,
.table {
  th,
  .th {
    font-size: $font-size--medium;
    font-weight: $font-weight--light;
  }
}

.table form.tr > div:not(.td) {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.td {
  display: table-cell;
  vertical-align: middle;
}

table td.rowheader {
  font-weight: bold;
  text-align: right;
}

table.lightweight {
  th {
    font-weight: normal;
    font-size: 100%;
    background-color: transparent;
    padding: 0 0.5rem;
    color: $color-text;
    border-bottom: none;
  }

  th.delete {
    font-size: 80%;
  }
}

table.line-item-table td,
.table.line-item-table .td {
  padding: 0.5rem;
}

form table td {
  position: relative;
}

table.table-just-center {
  margin-left: auto;
  margin-right: auto;
}

h2.pre-table-no-headers {
  margin-bottom: 0;
}

table.zebra tr:nth-of-type(odd),
.table.zebra > .tr:nth-of-type(odd),
.zebra .stripe:nth-of-type(odd),
.zebra.stripe:nth-of-type(odd) {
  background-color: $color-white;
}

table.zebra tr:nth-of-type(even),
.table.zebra > .tr:nth-of-type(even),
.zebra .stripe:nth-of-type(even),
.zebra.stripe:nth-of-type(even) {
  background: $color-background-gray--light;
}

table .line-item td input,
table .line-item td textarea {
  margin-bottom: 0;
}

table input[type='checkbox'] {
  margin-bottom: 0;
}

/* stylelint-enable no-descending-specificity */
