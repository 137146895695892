.disabledElement {
  opacity: 0.5;
  cursor: not-allowed;

  &:hover {
    background: transparent;
  }
}

.textTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
