@import 'styles/variables';

.noClick {
  position: relative;
}

.restoreDefault {
  color: $color-brand-blue;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -1.7rem;
}

.saveContainer {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  padding-right: 1rem;
}
