.proposal-summary-wrapped-content {
  &__alert {
    width: 85%;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    font-size: $font-size--medium;
    font-weight: $font-weight--light;
  }

  &__lost-changes {
    margin-bottom: 0.5rem;
  }

  @media (max-width: $width-sm) {
    &__alert {
      margin-top: 0;
    }
  }
}
