@import 'styles/variables';

.header {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  font-weight: $font-weight--light;
}

.xSmallSpacing {
  padding-bottom: $spacing--tiny;
}

.smallSpacing {
  padding-bottom: $spacing--default;
}

.mediumSpacing {
  padding-bottom: $spacing--medium;
}

.largeSpacing {
  padding-bottom: $spacing--large;
}

.hugeSpacing {
  padding-bottom: $spacing--huge;
}

.underlined {
  border-bottom: 1px solid $color-border;
}

.rightSection {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
}

.mainSection {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0;
}

.additionalSection {
  margin-left: 0;
}

.middleSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-left: $spacing--huge;
  margin-bottom: -$spacing--small;
  margin-right: -$spacing--small;
}

.middleSectionItem {
  margin: $spacing--small;
}

.rightButton {
  margin-left: $spacing--medium;
}
