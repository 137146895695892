@import 'styles/variables';

//by @tobiasahlin with ❤: https://tobiasahlin.com/spinkit/

.loadingDots {
  margin: auto;
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
}

.loadingDotsDot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.loadingDotsDot::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: $color-brand-blue;
  border-radius: 100%;
  animation: loading-dots-keyframes 1.2s infinite ease-in-out both;
}

.loadingDotsWhite {
  .loadingDotsDot::before {
    background-color: $color-white;
  }
}

.loadingDotsSmall {
  width: 1.125rem;
  height: 1.125rem;

  .loadingDotsDot::before {
    width: 14%;
    height: 14%;
  }
}

.loadingDotsLarge {
  width: 7rem;
  height: 7rem;
}

.loadingDotsDot1 {
  composes: loadingDotsDot;
}

.loadingDotsDot2 {
  composes: loadingDotsDot;
  transform: rotate(30deg);

  &::before {
    animation-delay: -1.1s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot3 {
  composes: loadingDotsDot;
  transform: rotate(60deg);

  &::before {
    animation-delay: -1s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot4 {
  composes: loadingDotsDot;
  transform: rotate(90deg);

  &::before {
    animation-delay: -0.9s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot5 {
  composes: loadingDotsDot;
  transform: rotate(120deg);

  &::before {
    animation-delay: -0.8s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot6 {
  composes: loadingDotsDot;
  transform: rotate(150deg);

  &::before {
    animation-delay: -0.7s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot7 {
  composes: loadingDotsDot;
  transform: rotate(180deg);

  &::before {
    animation-delay: -0.6s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot8 {
  composes: loadingDotsDot;
  transform: rotate(210deg);

  &::before {
    animation-delay: -0.5s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot9 {
  composes: loadingDotsDot;
  transform: rotate(240deg);

  &::before {
    animation-delay: -0.4s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot10 {
  composes: loadingDotsDot;
  transform: rotate(270deg);

  &::before {
    animation-delay: -0.3s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot11 {
  composes: loadingDotsDot;
  transform: rotate(300deg);

  &::before {
    animation-delay: -0.2s !important; /* stylelint-disable-line declaration-no-important */
  }
}

.loadingDotsDot12 {
  composes: loadingDotsDot;
  transform: rotate(330deg);

  &::before {
    animation-delay: -0.1s !important; /* stylelint-disable-line declaration-no-important */
  }
}

@keyframes loading-dots-keyframes {
  0%,
  39%,
  100% {
    background-color: $color-white;
  }

  40% {
    background-color: $color-brand-blue;
  }
}
