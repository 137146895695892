@import 'styles/variables';

.container {
  display: flex;
}

.content {
  flex: 1;
}

.rightSection {
  display: flex;
  align-items: center;
}

.leftSection {
  composes: rightSection;
}

.rightSectionItem {
  margin-left: $spacing--small;
}

.leftSectionItem {
  margin-right: $spacing--small;
}
