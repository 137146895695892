@import 'styles/variables';

.paginationContainer {
  display: flex;
  justify-content: center;
  font-size: $font-size--base;
  align-items: center;
}

.pageInfo {
  margin: 0 $spacing--default;
}

.paginationButton {
  border: solid 1px;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: $color-white;

  &::before {
    content: ' ';
    width: 0;
    height: 0;
    display: block;
    margin: auto;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
}

.activeButton {
  border-color: $color-brand-black;

  &:hover,
  &:focus {
    outline: none;
    cursor: pointer;
    background-color: $color-gray--lightest;
  }
}

.disabledButton {
  border-color: $color-text--gray;
  cursor: default;
}

.nextArrow::before {
  border-left: 6px solid $color-brand-black;
}

.disabledNextArrow::before {
  border-left-color: $color-text--gray;
}

.prevArrow::before {
  border-right: 6px solid $color-brand-black;
}

.disabledPrevArrow::before {
  border-right-color: $color-text--gray;
}
