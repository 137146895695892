@import 'styles/variables';

.title {
  margin-bottom: 0;
}

.openIcon {
  max-width: 1rem;
}

.editConstitutionLabel {
  display: inline-block;
  margin-left: $spacing--small;
}

.itemsContainer {
  display: flex;
}

.editorContainer {
  display: flex;
  align-items: center;
}

.controlButtons {
  display: flex;
  justify-content: flex-end;
  margin-right: $spacing--small;
}

.inputContainer {
  margin: 0 $spacing--small 0 0;
}

.controlButton {
  margin-left: $spacing--small;
}

.readOnlyBottomMargin {
  margin-bottom: $spacing--large;
}

@media print {
  .constitutionLinkBox {
    display: none;
  }
}
