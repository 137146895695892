@import 'styles/variables';

$fadeOutTimer: 500ms;

.alert {
  composes: alert from global;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }
}

.info {
  composes: alert-info from global;
}

.danger {
  composes: alert-danger from global;
}

.fadeOut {
  animation: fadeOut ease $fadeOutTimer;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.button {
  padding: 0.1rem $spacing--small;
  max-height: 1.9rem;
  border: 1px solid;
  border-radius: $spacing--tiny;
}

.infoButton {
  composes: alert-info from global;

  &:hover {
    background-color: $color-alert-info-button--hover;
  }

  &:focus {
    box-shadow: 0 0 0 0.1rem rgba($state-info-text, 0.5);
    outline: none;
  }
}

.dangerButton {
  composes: alert-danger from global;

  &:hover {
    background-color: $color-alert-danger-button--hover;
  }

  &:focus {
    box-shadow: 0 0 0 0.1rem rgba($state-danger-text, 0.5);
    outline: none;
  }
}

.additional {
  display: flex;
  justify-content: space-between;
  margin: -0.3rem -0.5rem -0.3rem $spacing--small;
}

.closeButton {
  margin-left: 0.5rem;
  position: initial;
}

.closeButtonInfo {
  svg {
    fill: $state-info-text;
  }
}

.closeButtonDanger {
  svg {
    fill: $state-danger-text;
  }
}
