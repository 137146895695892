@import 'styles/variables';

.messages {
  overflow-y: auto;
  flex-grow: 1;

  .avatar {
    height: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: top;
    width: 1.5rem;
    fill: $color-brand-blue;
    border-radius: 2rem;
  }
}

.text {
  display: inline-block;
  line-height: 1.5rem;
  margin-left: 0.7rem;

  // rudimentary start to formatting of the content
  ul,
  ol {
    margin-left: 1rem;
    margin-bottom: $spacing--medium;
  }

  li {
    margin-top: $spacing--medium;
    list-style-type: disc;
  }

  pre,
  code {
    overflow-wrap: break-word;
    white-space: pre-wrap;
    overflow: hidden;
  }

  code {
    background: #efefef;
    padding: 0.2rem 0.3rem;
    border-radius: 0.2rem;
  }
}

.message {
  border-bottom: 1px solid #eee;
  padding: $spacing--medium $spacing--large;
  position: relative;
  margin-bottom: 0;
  composes: d-flex flex-row from global;

  a {
    color: $color-brand-blue;
  }

  &.system {
    background: rgba(0 0 0 / 15%);

    .text {
      max-height: 1.4rem;
      overflow: hidden;
    }
  }
}

.readMore {
  display: inline-block;
  width: 3rem;
  right: 1rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-left: 2rem;

  > button {
    padding: 0.5rem;
  }
}
