@import 'styles/variables';

.addButton {
  composes: ui-icon-button from global;
  padding: 0;
  transition: box-shadow, color, fill, 150ms;
  border: 1px solid transparent;
  height: $font-size--larger;
  width: $font-size--larger;
  margin: 0 0 0 $spacing--tiny;

  > svg {
    fill: $color-gray;
    height: $font-size--larger;
    width: $font-size--larger;
  }

  &:hover,
  &:focus {
    outline: 0;
    background-color: $color-icon-button--background-hover;

    > svg {
      fill: $color-link;
    }
  }
}
