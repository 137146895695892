@import 'styles/variables';

.icon {
  margin-left: $spacing--tiny;
  display: flex;

  svg {
    height: 1rem;
    width: 1rem;
    fill: $color-text--gray;
  }
}

.button {
  composes: btn-reset from global;
  border-radius: $spacing--tiny;
  padding: $spacing--tiny;
  margin: -$spacing--tiny;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border: 1px solid transparent;
  color: $color-text--gray;
  font-weight: $font-weight--light;
  font-size: $font-size--base;

  &Active,
  &:focus,
  &:hover {
    color: $color-text;
    outline: none;

    svg {
      fill: $color-text !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.buttonRight {
  justify-content: flex-end;
}

.content {
  margin-top: $spacing--medium;
}
