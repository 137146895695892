// This file is for (a) overly specific style rules that should be gradually removed
// or migrated into more appropriate components as time allows.

@import '../../../assets/stylesheets/mixins/spinner_1';

/* stylelint-disable no-descending-specificity */

@media screen and (min-width: 590px) {
  .fade-out {
    .fade-out__item {
      opacity: 0;
      transition: opacity 0.1s linear;
    }

    &:hover {
      .fade-out__item {
        opacity: 1;
      }
    }
  }
}

.sign-in-description {
  &_muted {
    color: $color-gray;
  }
}

.sign-in-additional-button {
  display: inline-block;
  margin: 1rem 0.25rem 0;
  height: 2.625rem;
  min-width: 11.65rem;
  padding-left: 0.5rem;
  border-color: $color-border;
  background-color: $color-background-gray--light;

  &:hover {
    border-color: $color-gray;
    background-color: $color-gray--lighter;
  }

  &__img {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.5rem;
  }

  &__label {
    letter-spacing: 0;
    color: rgb(109 109 109);
    line-height: 1.7;
    font-weight: $font-weight--default;
  }
}

hr.sign-in-separator {
  border: 0;
  height: 0.125rem;
  background: $color-background-gray--light;
  margin: 1.75rem 0 1.25rem;
}

.sign-in-btn {
  margin-top: 2rem;
  width: 12.5rem;
}

.orange-text {
  color: $color-orange;
}

.hover {
  background-color: $color-list-item-hover;
}

.draggable {
  cursor: move;
}

.nobreak img {
  vertical-align: text-top;
}

.annotation {
  font-style: italic;
  font-size: 90%;
}

// Likely redundant with Foundation
.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.inline-button {
  display: inline-block;
  vertical-align: top;
}

.cursor-default {
  cursor: default;
}

.padded-top {
  padding-top: 0.5rem;
}

.no-results {
  padding-top: 0.25rem;
}

.italic {
  font-style: italic;
}

.nobreak {
  white-space: nowrap;
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.clear-block {
  clear: both;
  display: block;
  visibility: hidden;
}

.spacer {
  width: 1.75rem;
}

.change-sub-title {
  font-size: 105%;
  font-style: italic;
}

.changed-value {
  font-size: $font-size--body;
  line-height: 1rem;
  padding: 0 0.5rem 0.5rem;
  display: block;
}

.policies .changed-value {
  margin-left: 0.5rem;
}

p:empty {
  display: none;
}

.descriptive-text-block {
  line-height: 1.4;
  margin: 0 0 0.5rem;

  p {
    margin: 0 0 1rem;
  }
}

h2.header--medium p {
  // simple_format wraps output in a p tag, but we don't want the margin
  // that comes with a p tag when we're displaying this in a header.
  margin-bottom: 0;
}

table.holacracy-stats {
  th {
    padding: 0.5rem;
    vertical-align: bottom;
    font-size: $font-size--small;
    font-weight: $font-weight--medium;
    letter-spacing: 0.04em;
  }

  .header--holacracy-stats {
    div {
      border-bottom: 1px solid $color-border;
    }
  }

  tbody tr:nth-of-type(even) {
    background: $color-background-gray--light; /* W3C */
  }
}

#holacracy-help {
  padding-top: 0;
  padding-right: 1.25rem;
}

.lessons.main.show,
.holacracy_help.main.pages,
.index.lessons.main,
.main.role_notes.show,
.assignment_records.index.main,
.announcements.index.main,
.announcements.main.show,
.index.main.people,
.main.membership_lists.people,
.habits.index.main {
  .date_range {
    float: left;
    margin-right: 1rem;
  }

  .status.tiny-caption {
    margin-right: 1rem;
  }
}

// Special header for the Holacracy Habits page.
.page__header--fancy {
  .box {
    color: #fff;
    margin-bottom: 0;
    overflow: hidden;
    padding: 3rem 0;
    position: relative;
    text-align: center;

    .page__title {
      font-size: 2.25rem;
    }

    .page__subtitle {
      font-size: $font-size--medium;
      font-weight: $font-weight--light;
      margin-top: 0.5rem;
    }

    h1,
    h2 {
      color: #fff;
      display: block;
    }

    &::before {
      background: rgb(0 0 0 / 70%);
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
}

.page__header--holacracy-help {
  .box {
    background-image: url('../../../assets/images/holacracy-help-background.jpg');
    background-size: cover;
  }

  background-color: $color-white;
  border-bottom: none;
}

.selectize-control.form-control {
  border: none;
  height: 2.375rem;
  padding: 0;
}

.selectize-input {
  border: 1px solid 1px solid $color-border;
  border-radius: 0.2rem;
}

body.organizations.show #pageContent {
  padding: 0;
  margin: 0;
}

.inner-page-border {
  border: 8px solid $color-border;
  padding: 1.25rem 1.875rem;
  display: block;
  position: relative;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  border-radius: 0.5rem;
}

.inner-page-border.skinny {
  padding: 1rem 1.875rem;
}

dt {
  margin-top: 1em;
  font-weight: $font-weight--heavy;
}

.full {
  width: 100%;
}

body.assignment_records.index {
  table th.date-col {
    width: 16.25rem;
  }
}

// Not sure where this outline is coming from, but it is weird
label {
  outline: 0;
}

.tool-tip-target {
  color: $color-text--gray;
  cursor: pointer;
}

#pageHeader {
  padding: 0;
  margin: 0.5rem 0 0;
  font-size: 1.8125rem;
  height: 2.75rem;
}

#pageHeader a,
.ui-dialog-titlebar-close,
input.link-small {
  outline-width: 0;
}

.flash {
  &.container {
    margin-top: 1rem !important; /* stylelint-disable-line declaration-no-important */
  }
}

.error-text {
  color: $color-orange;
  font-weight: $font-weight--heavy;
}

img {
  border: 0;
}

.system_message {
  margin: 0 0.5rem;
}

.system_message #s_msg_image {
  float: left;
  padding: 0 1.25rem;
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.system_message #s_msg_text {
  padding: 2rem 1.25rem 0 1.75rem;
}

.system_message #s_msg_text h1 {
  font-size: 1.625rem;
}

.system_message #s_msg_text p {
  font-size: 1rem;
}

#footer {
  padding: 0.2rem 0.5rem 1rem 1rem;
  margin: 1.25rem 0 0;
  border-top: 2px solid $color-border;
  font-size: 0.5rem;
}

.alert {
  position: relative;
}

#circle-members {
  width: 100%;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.loading-spinner {
  min-height: 3.125rem;
  background: transparent url('../../../assets/images/spinner.gif') no-repeat 50% 50%;
}

.waiting-spinner {
  background: transparent url('../../../assets/images/waiting.gif') no-repeat 0 0;
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

/* ***************** */

.group {
  @include clearfix;
}

.auto_complete {
  z-index: 101;
}

.ui-draggable {
  .ui-dialog-titlebar {
    cursor: move !important; /* stylelint-disable-line declaration-no-important */
  }
}

.ui-dialog-buttonset button:disabled {
  filter: alpha(opacity=55);
  opacity: 0.55;
}

a.election-expired {
  color: $color-red;
  padding: 0 0.125rem;
  cursor: default;
}

.removed-person {
  color: $color-text--gray;
  font-style: oblique;
}

.stop-scroll {
  overflow: hidden;
  // and since overflow:hidden only indicates there's not scroll action if there's overflow...
  // we'll make the container overflow - this is to fix a chrome issue with selectize
  height: 100%;
  margin-bottom: 0.125rem;
}

// See https://www.pivotaltracker.com/story/show/118526335
.lead-link-assign.role-fillers {
  input#person_name {
    width: 15rem;
  }
}

// person page, role page
.domain,
.accountability {
  p {
    margin-bottom: 0;
  }
}

.organization-search-results {
  .result-count {
    font-weight: $font-weight--medium;
    font-size: 1.2rem;
  }

  .hit {
    padding: 0.5rem 0 0.5rem 0.5rem;
    min-height: 4.5rem;

    .right-links {
      float: right;

      a {
        margin-left: 0.5rem;
      }
    }

    li {
      border: none;
      padding: 0;
      margin: 0;
    }

    .title {
      font-size: 1.3rem;
      font-weight: $font-weight--medium;

      .org-id {
        font-size: 0.9rem;
      }
    }

    .details {
      font-size: 0.8rem;
      margin-top: 1rem;
    }

    .links {
      height: 4.5rem;
      margin-top: 1.25rem;

      .columns:first-child {
        padding-left: 0;
      }
    }
  }
}

.api_keys.index.main {
  td.label,
  th.label {
    width: 30%;
  }

  td.revoke-button,
  th.revoke-button {
    width: 20%;
    text-align: right;
  }

  .key-row {
    .button {
      margin: 0.25rem;
    }
  }
}

.ui-state-active {
  background: $color-brand-blue !important; /* stylelint-disable-line declaration-no-important */
  border-color: 1px solid $color-brand-blue !important; /* stylelint-disable-line declaration-no-important */

  &:hover {
    background: $color-brand-blue-darker !important; /* stylelint-disable-line declaration-no-important */
    border-color: $color-brand-blue-darker !important; /* stylelint-disable-line declaration-no-important */
  }
}

.btn.fa::before {
  top: 0.2rem;
  position: relative;
}

.tactical-steps {
  position: relative;
}

.controls {
  .icon {
    color: $color-brand-blue;
    cursor: pointer;
  }
}

.progress-bar {
  height: 1.25rem;
}

.small-menu-chevron {
  font-size: 0.5625rem !important; /* stylelint-disable-line declaration-no-important */
  padding-left: 0.2rem;
}

@mixin react-select-border-color($color) {
  .is-focused:not(.is-open) > .Select__control {
    border-color: $color;
  }

  .is-focused > .Select__control {
    outline: none !important; /* stylelint-disable-line declaration-no-important */
    border-color: $color;
    box-shadow: 0 0 0.5rem $color;
  }

  .Select__control {
    border-color: $color;
  }
}

// Bootstrap kind-of-polyfills
.form-group {
  .form-control-feedback {
    display: none;
  }

  &.has-danger,
  &.has-warning {
    .form-control-feedback {
      display: block;
    }
  }

  &.has-danger {
    @include react-select-border-color($color-red);
  }

  &.has-success {
    @include react-select-border-color($color-green);
  }
}

/* stylelint-enable no-descending-specificity */
