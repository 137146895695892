.avatar {
  @mixin base-avatar($size) {
    background-color: $color-gray--lighter;
    border-radius: 50%;
    background-size: $size $size;
    width: $size;
    height: $size;
    margin-right: 0.75rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  &--tiny {
    @include base-avatar(1.875rem);
  }

  &--small {
    @include base-avatar(2.5rem);
  }

  &--medium {
    @include base-avatar(3.5rem);
  }

  &--large {
    @include base-avatar(7rem);
  }
}
