@import 'styles/variables';

.placeholder {
  composes: base_no_items_msg from global;
}

.workspaceCssVersion {
  composes: section-wrapper from global;

  .item {
    // this should mirror app/assets/stylesheets/components/haml/list_view.scss:4
    border-bottom: $list-item-border-thickness solid $color-page-background-blue;
    padding: $spacing--default;
    align-items: center;
  }
}

.classicCssVersion {
  margin-bottom: $spacing--large;

  > .item {
    border-top: 1px solid $color-border;
    border-bottom: 1px solid transparent;
  }

  > .item:last-child {
    border-bottom: 1px solid $color-border;
  }
}

.container.noTopBorder > .item:first-child {
  border-top: none;
}
