@import 'styles/variables';

.advancedSection {
  padding-top: $spacing--medium;

  &Opened {
    background: $color-background-gray--light;
  }
}

.suggestionRow {
  border-top: 1px solid $color-gray--light;
  padding: $spacing--default;
}

.arrow {
  color: $color-gray--light;
}

.changeToV4Button {
  display: inline-flex;
}

.changeToV4ButtonTitle {
  margin-right: $spacing--small;
}

.actionButton {
  width: 100%;
}
