.refinery-select-items {
  &__body {
    display: grid;
    grid-template-columns: min-content 1fr;
    margin-top: 1em;
    margin-right: $refinery-right-col-width;
  }

  &__controls {
    grid-column: 1;
    padding-right: 2em;
  }

  &__checkboxes {
    grid-column: 2;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
  }

  &__checkbox {
    width: calc(100% / 2);
    padding-right: 0.7rem;

    label {
      font-size: 0.85rem;
      font-weight: $font-weight--medium;
    }
  }

  @media (max-width: $width-lg) {
    &__checkbox {
      width: 50%;
    }
  }

  @media (max-width: $width-sm) {
    &__checkbox {
      width: 100%;
    }
  }
}
