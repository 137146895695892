@import 'styles/variables';

.chats {
  border-right: 1px solid $color-brand-blue;
  float: left;
  padding-bottom: 6rem;
  overflow-y: auto;
  min-width: 10rem;
  background: #efefef;
  border-bottom-left-radius: 0.2rem;

  .timing {
    background: $color-gray--lighter;
    color: $color-text--gray;
    font-size: $font-size--small;
    text-transform: uppercase;
    padding: $spacing--tiny $spacing--medium;
  }

  .chat {
    border: 0;
    cursor: pointer;
    display: block;
    text-align: left;
    width: 100%;
    padding: $spacing--small $spacing--medium;
    outline: none;

    &:not(:last-of-type) {
      border-bottom: 1px solid #dedede;
    }

    &:hover {
      background: #dedede;
    }

    .title {
      font-weight: $font-weight--medium;
      font-size: $font-size--base;
      color: $color-text--gray;
    }

    &.active {
      background: $color-brand-blue;

      .title {
        color: $color-white;
      }
    }
  }

  .newChat {
    text-transform: uppercase;
    color: $color-text--gray;
    letter-spacing: 0.05rem;
    font-size: 90%;

    &.active {
      background: transparent;
    }

    i {
      color: $color-text--gray;
      margin-right: $spacing--small;
    }
  }
}
