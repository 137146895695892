@import 'styles/variables';

.editorContainer {
  outline: 1px solid $color-brand-blue; /* stylelint-disable-line declaration-property-unit-disallowed-list */
  border-radius: $border-radius-sm;
  background-color: $color-brand-blue;
  box-sizing: border-box;

  --ck-focus-ring: 1px solid var(--theme-primary-selected);
}
