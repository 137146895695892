.leftPadding {
  padding-left: 0.75rem;
}

.rightPadding {
  padding-right: 0.75rem;
}

.topPadding {
  padding-top: 1rem;
}

.bottomPadding {
  padding-bottom: 1rem;
}
