@import 'styles/variables';

.headerWrapper {
  display: flex;
  align-items: center;

  .label {
    font-size: $font-size--small;
    color: $color-gray;
    padding: 0.15rem 0.75rem;
    margin-left: 1rem;
    border-left: 1px solid $color-border--medium;
  }
}

.summary {
  margin: 0 0 $spacing--medium;
  color: $color-text--gray;
}
