.proposal-part-circle-footer {
  width: 100%;

  &__move-items {
    padding-top: 0.7rem;
  }

  &__keep-delete-switch {
    padding-top: 1rem;
  }
}
