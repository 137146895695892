@import 'styles/variables';
@import 'react-datepicker/dist/react-datepicker-cssmodules';

.datePicker {
  :global {
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: $color-brand-blue;
    }
  }
}
