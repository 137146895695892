/* stylelint-disable max-nesting-depth  */

.agenda-container {
  padding: 0;

  li,
  .item {
    border: 1px solid $color-border;
    display: flex;
    line-height: 0.8;
    margin: 0.3rem 0.3rem 0.3rem 1rem;
  }

  &::before {
    background: $color-border;
    content: '';
    position: absolute;
    top: 0;
    width: 0.0625rem;
    height: 100%;
    z-index: 1;

    @media (max-width: $width-md) {
      background: none;
    }
  }
}

.agenda-item__content {
  display: flex;
  position: relative;
  user-select: none;
  width: 100%;
}

.agenda-item__content__text {
  flex: 1 1 auto;
  padding: 0.75rem 0;
}

.agenda-item__content__link {
  flex: 0;

  .btn-link {
    padding: 0;
  }
}

.agenda_check,
.agenda_item_text,
.agenda_remove {
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.agenda_check,
.agenda_remove {
  color: $color-gray--lighter;
  flex: 0 0 auto;
  padding: 0.5rem;
  display: flex;
  align-items: center;

  svg {
    fill: $color-gray--lighter;
  }

  &:hover {
    color: $color-brand-blue;

    svg {
      fill: $color-brand-blue;
    }
  }
}

.agenda-item {
  cursor: pointer;
  position: relative;
  border-radius: 1rem;
  color: $color-text;

  input[type='radio'] {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:hover + .agenda-item__content {
      background: $color-list-item-hover;

      .agenda_remove {
        color: $color-brand-blue;
      }
    }
  }

  &.current {
    background: lighten($color-brand-blue--highlight, 45%);
    border: 1px solid $color-brand-blue;
    font-weight: 500;
  }

  &:not(.current) {
    padding-left: 0.125rem;
  }

  &.completed {
    .agenda_check {
      color: $color-brand-blue;

      svg {
        fill: $color-brand-blue;
      }
    }

    .agenda_item_text {
      text-decoration: line-through;
    }
  }

  &:hover {
    background: $color-list-item-hover;
  }
}

// Slated for removal or refactor into class-based component.

#agenda-container {
  // TODO: Can probably get rid of this now?
  .tactical-step {
    @media (max-width: $width-md) {
      margin-right: 1rem;

      &.selected {
        border-right: 1px solid $color-border;
      }
    }

    position: relative;
    z-index: 4;

    a {
      border-bottom: 1px solid $color-gray--lightest;
      display: block;
      padding: 0.75rem 0 0.75rem 1rem;

      &.selected {
        border-left: 3px solid $color-white;
        border-right: 1px solid $color-white;
        border-bottom: 1px solid $color-gray--lighter;
        border-top: 1px solid $color-gray--lighter;
        margin: -0.06 0 0;
        position: relative;
        background: $color-brand-black;
        color: $color-white;
        font-weight: 500;
      }

      &:last-of-type {
        border-top: 1px solid $color-gray--lightest;
      }
    }

    &:first-child {
      a {
        border-top: 1px solid $color-gray--lighter;
      }
    }
  }
}

/* stylelint-enable max-nesting-depth  */
