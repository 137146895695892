@import 'styles/variables';

.container {
  composes: d-flex flex-row from global;
  align-items: center;
  color: $color-gray;
  font-size: $font-size--base;
  margin-top: $spacing--small;
  margin-left: $spacing--small;

  .thumbText {
    opacity: 0;
    margin-left: $spacing--default;
  }

  &:hover {
    .thumbText {
      opacity: 1;
    }
  }
}

.thumb {
  composes: ui-icon-button ui-icon-button--sm from global;
  color: $color-gray;

  i {
    font-size: $font-size--medium;
  }
}

.selected {
  &.thumb {
    i {
      color: $color-brand-blue;
    }
  }
}
