@import 'styles/variables';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $spacing--medium;
}

.enterNoticeContainerBlock {
  margin-top: -1rem;
}

.enterNoticeContainerFlex {
  margin-top: -0.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
