/* stylelint-disable no-descending-specificity */

.inline-edit-table {
  &:not(#add-item) .td:last-of-type {
    text-align: right !important; /* stylelint-disable-line declaration-no-important */
    width: 3.125rem !important; /* stylelint-disable-line declaration-no-important */
    padding: 0.25rem 1.375rem 0.25rem 0.25rem !important; /* stylelint-disable-line declaration-no-important */
    vertical-align: middle;
  }

  .tbody:empty::after {
    content: attr(data-none-defined);
    padding: 0.75rem 0;
    display: block;
    position: absolute;
  }

  .tbody:empty {
    display: block;
    margin: 0.25rem 0 2.8rem 0.75rem;
  }

  .th,
  .td {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  // WHY IS THIS BEING OVERRIDDEN BY APPLICATION?
  .td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    position: relative;
  }

  .td:first-of-type {
    padding-left: 0.75rem;
  }

  input {
    box-sizing: border-box;
  }

  .edit-controls {
    padding-left: 0;
    padding-right: 0;
    width: 4.375rem;
    position: absolute;
    right: 0.25rem;
    top: -0.25rem;
    cursor: pointer;
  }

  .remove-controls {
    white-space: nowrap;
    padding-top: 0.25rem;
  }

  .controls {
    font-size: 1.25rem;
  }

  .controls a {
    margin: 0;
    padding: 0;
    margin-right: -0.375rem;
    width: 1.56rem;
  }

  .tr.edit,
  .tr.edit .td {
    background-color: $color-background-blue--light;
    vertical-align: top;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .edit .td {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .remove-controls a {
    font-size: 1.75rem;
    cursor: pointer;
    display: inline-block;
    height: 2.75rem;
  }

  .remove-controls a.cancel {
    font-size: 1rem;
  }

  .item-link a,
  .add-item {
    font-size: 1rem;
    display: block;
  }

  .waiting-spinner {
    margin-left: -1.875rem;
    float: left;
  }
}

.inline-style-table {
  .controls {
    font-size: 1.25rem;
  }
}

/* stylelint-enable no-descending-specificity */
