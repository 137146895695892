@import 'styles/variables';

.container {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $color-gray;
  font-weight: $font-weight--light;
  font-size: $font-size--large;
}

.icon {
  height: 2rem;
  width: 2rem;
  fill: $color-gray;
}
