@import 'styles/variables';

.item {
  display: flex;
  align-items: baseline;
}

.input {
  margin-right: $spacing--small;
}

.label {
  font-weight: $font-weight--light;
  font-size: $font-size--medium;
  width: 100%;
}

.warning {
  color: $color-red;
}

.items {
  display: flex;
  flex-direction: column;
  width: 100%;
}
