@import 'styles/variables';

.container {
  height: 2rem;
  width: 2rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    .icon {
      fill: $color-brand-blue--highlight;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.125rem $color-brand-blue--clicked;

    .icon {
      fill: $color-brand-blue--clicked;
    }
  }
}
