@import 'styles/variables';

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.linkContainer {
  display: flex;
}

.bodyWrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: $spacing--small;
}

.roleName {
  margin-right: $spacing--small;
}

.footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  color: $color-gray;
}

.footerLeft {
  display: flex;
  align-items: baseline;
}

.roi {
  margin-left: $spacing--small;
}

.privateToCircle {
  display: flex;
  margin-left: $spacing--small;
}

.cursorPointer {
  cursor: pointer;
}

.projectIcon {
  height: 1rem;
  width: 1rem;
  fill: $color-text--gray;
}
