@import 'styles/variables';

.chatDialog {
  width: 50rem;
  box-shadow: 0 0 1rem rgb(0 0 0 / 20%);
  border-radius: 0.5rem;
  border: 5px solid rgba(0 0 0 / 30%); /* stylelint-disable-line */
  padding: 0;
  margin: 0.3rem;
  height: 30rem;

  &.maximized {
    // TODO: might be better to do something more mobile friendly?
    height: 99vh;
    width: 99vw;
  }

  @media only screen and (max-width: 767px) {
    max-width: 97vw;
    height: 91vh;
    max-height: 91vh;
  }
}

.messagesColumn {
  composes: d-flex flex-column from global;
  flex-grow: 1;
}

.dialogBody {
  height: calc(100% - 2.5rem);
  border-radius: 0 0 0.2rem 0.2rem;
  background: $color-white;
}

.header {
  background: $color-brand-blue;
  color: $color-white;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem 0.2rem 0 0;
  height: 2.5rem;

  h3 {
    margin-bottom: 0;
    font-weight: normal;
    flex-grow: 1;
    letter-spacing: 0.05rem;
  }

  .headerButtons button {
    background: transparent;
    border: none;
    color: $color-white;
    cursor: pointer;
    height: 2.2rem;
    outline: none;
    width: 2rem;
  }
}
