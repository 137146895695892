@import 'styles/variables';

.container {
  padding: $spacing--xtiny;
  background: $color-white;
  border-radius: 0.25rem;
  border: 1px solid $color-border;
  display: grid;
  grid-template: auto auto / auto;
  grid-template-areas:
    'body'
    'footer';
  grid-gap: $spacing--small;

  &:hover,
  &:focus {
    border-left: 1px solid $color-gray;
    border-right: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
  }
}

.body {
  grid-area: body;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: $spacing--small;
  // In a word, the following line is needed because this is a grid container item
  // but we want the text inside be either wrapped or truncated.
  min-width: 0;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $spacing--xtiny;
  font-size: $font-size--small;
}

.footerLeft {
  width: 100%;
}

.details {
  color: $color-text--gray;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  span {
    padding-left: $spacing--small;
  }
}

.icon {
  svg {
    width: $font-size--small;
    height: $font-size--small;
  }
}

.role {
  padding-left: $spacing--small;
}

.avatar {
  padding: 0 $spacing--small;
}

.current {
  border-top: 5px solid var(--theme-success);
}

.waiting {
  border-top: 5px solid var(--theme-warning);
}

.done {
  border-top: 5px solid var(--theme-info);
}

.future {
  border-top: 5px solid $color-status-future;
}
