.voting-widget {
  font-size: 1.125rem;
  font-weight: bold;

  div {
    display: inline-block;
  }

  .vote-icons {
    white-space: nowrap;

    i {
      margin-right: 0.65rem;
      opacity: 0.7;
      font-size: 1.25rem;

      &:hover {
        color: $color-brand-blue-darker !important; /* stylelint-disable-line declaration-no-important */
        cursor: pointer;
        opacity: 1;
      }

      &.selected {
        color: $color-brand-blue;
        opacity: 1;
      }
    }
  }

  .tagline {
    margin-right: 1rem;
  }

  .feedback {
    margin-left: 0.35rem;
    font-size: 0.9rem;
  }

  .hidden {
    display: none;
  }
}
