/* stylelint-disable selector-max-specificity */

#review_proposal_controller {
  .proposal-info {
    margin: 0.25rem 0;
  }

  .tension {
    margin: 1rem 0;
    background-color: $color-background-gray--light;
    border: 1px solid $color-border;
    padding: 1rem;
    border-radius: $border-radius;
    margin-bottom: 1rem;
  }

  .cta {
    text-align: center;
    padding: 1.5rem;
    margin: 3.5rem 0 1.5rem;
    background-color: $color-background-blue--light;
  }

  .section__header {
    padding: 1rem 0;
    border-bottom: 1px solid #dadada;
    font-weight: 300;
  }

  .response-deadline-notice {
    margin-bottom: 1.5rem;
    font-weight: 300;
    font-size: 1.05rem;
  }

  .loading-container {
    position: relative;
  }

  .loading {
    position: absolute;
    background: $color-white url('../../../assets/images/spinner.gif') no-repeat 50% 50%;
    width: 100%;
    height: 6.25rem;
    font-size: 1.7em;
    font-weight: $font-weight--heavy;
    color: $color-gray;
    text-align: center;
    filter: alpha(opacity=80);
    opacity: 0.75;
    box-sizing: border-box;
  }

  .attribute-pair {
    display: flex;
  }

  .attribute-title,
  .attribute-pair .name,
  .proposal-summary .section-header,
  .proposal-summary .sub-title {
    font-weight: $font-weight--heavy;
  }

  .proposal-summary {
    .attribute-title,
    .section-header,
    .sub-title {
      margin-bottom: 0.25rem;
    }

    .section {
      margin: 0;
    }

    .change,
    .section.item.accountabilities,
    .section.item.domains {
      padding: 0.25rem 0 0.5rem;
    }

    .accountabilities ul,
    .domains ul {
      margin-left: 0.75rem;
    }

    .change:not(.attribute) {
      padding: $spacing--xtiny 0 0 $spacing--medium;
      line-height: 1.125rem;
    }

    .change:not(.attribute)::before {
      content: '•';
      float: left;
      margin-left: -1rem;
      padding: $spacing--xtiny 0.5rem 0 0;
      color: $color-green;
      font-size: 1.5rem;
    }

    .move-list {
      margin: 1rem 0 1.5rem;
    }

    .move-list li.move-item {
      padding: 0.25rem 0 0 1.5rem;
    }
  }
}

.warning_message {
  text-align: center;
  padding: 1rem 0;
  width: 100%;
  margin: 1rem 0;
  border: 2px solid $color-brand-blue--lightest;
  color: $color-brand-blue--lightest;
  box-sizing: border-box;
  border-radius: $border-radius-lg;
}

/* stylelint-enable selector-max-specificity */
