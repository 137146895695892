.shepherd-theme-custom {
  &[data-popper-placement='top'] {
    margin-top: -10px; // stylelint-disable-line
  }

  &[data-popper-placement='bottom'] {
    margin-bottom: -10px; // stylelint-disable-line
  }

  &[data-popper-placement='left'] {
    margin-left: -10px; // stylelint-disable-line
  }

  &[data-popper-placement='right'] {
    margin-right: -10px; // stylelint-disable-line
  }

  a {
    word-break: auto-phrase;
  }

  .shepherd-header {
    padding: $spacing--small $spacing--medium;
  }

  .shepherd-text,
  .shepherd-footer {
    font-size: $font-size--base;
  }

  .shepherd-text {
    padding: $spacing--medium $spacing--medium;
  }

  .shepherd-footer {
    justify-content: center;
  }

  .shepherd-button {
    padding: $spacing--xtiny $spacing--small;

    &.shepherd-button-primary {
      background: $color-brand-blue;
    }

    &.shepherd-button-secondary {
      border: 1px solid $color-brand-blue;
      background: $color-white;
      color: $color-brand-blue;
    }
  }

  &.headerless {
    padding-bottom: $spacing--default;

    .shepherd-text {
      padding: 0 $spacing--huge $spacing--large;
      font-size: $font-size--base;
    }

    ul {
      margin-bottom: $spacing--medium;

      li {
        list-style-type: disc;
        margin-left: $spacing--large;
      }
    }
  }
}

body.shepherd-active::after {
  content: '';
  display: block;
  opacity: 0.4;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
}

.shepherd-element.shepherd-open,
.shepherd-target.shepherd-enabled {
  z-index: 10000001;
  position: relative;
}
