@import 'styles/variables';

.type {
  composes: type tiny-caption from global;
}

.description {
  composes: description from global;
}

.privateIcon {
  svg {
    height: 1rem;
    width: 1rem;
    fill: $color-gray;
  }

  margin-right: $spacing--tiny;
}
