@import 'styles/variables';

.list,
.item {
  margin-bottom: $spacing--medium;
}

.title {
  margin-bottom: 0;
}

.emptyValue {
  font-weight: $font-weight--light;
  font-style: italic;
}
