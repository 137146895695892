@import 'styles/variables';

.container {
  margin-bottom: 0;
  display: flex;
  align-items: baseline;
}

.input {
  font-size: $font-size--small;
  margin-right: $spacing--small;
}

.warning {
  color: $color-red;
}

.label {
  display: flex;
  align-items: center;
  font-weight: $font-weight--light;
  font-size: $font-size--medium;
}

.disabledWithpremiumCallout {
  opacity: 0.6;
}
