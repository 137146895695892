/* stylelint-disable no-descending-specificity */

body {
  // fixes a bug with bootstrap modals that cause the background to pop
  &.modal-open {
    padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
  }
}

.page__content {
  position: relative;

  &.no-breadcrumb {
    .page__header {
      padding-top: 1.875rem;
    }
  }
}

.inline-react-component {
  display: inline-block;
}

.page__header {
  margin-bottom: 1rem;
  position: relative;
}

.page__header--detailed {
  padding-top: 0.25rem;

  .avatar--medium {
    margin-top: 0.125rem;
  }
}

// Inline-block/floating is kinda hackish - will probably want to clean up.
.page__header,
.section__header {
  h2,
  h3,
  h4,
  .button,
  .button-group,
  .controls {
    display: inline-block;
  }

  .button,
  .button-group,
  .controls {
    float: right;
  }

  .controls {
    font-size: 1rem;
  }

  .button {
    margin-left: 0.625rem;
    margin-bottom: 0;
  }
}

.header__sub {
  display: inline-block;
  font-size: $font-size--body;
  margin-left: 0.625rem;
  margin-top: -0.125rem;

  a {
    margin-left: -0.1875rem;
  }
}

.page__main {
  padding-bottom: 3.75rem;
  position: relative;
}

.content__sidebar {
  padding: 0;
  position: relative;

  a {
    word-break: break-all;
  }
}

.sidebar__sections {
  padding-right: 1.875rem;
}

.sidebar__meeting-buttons {
  border-bottom: 1px solid $color-border;
}

.content__section,
.section {
  margin: 1.875rem 0;

  &:first-child {
    margin-top: 0;
  }
}

.section--divided {
  border-bottom: 1px solid $color-border;
  padding-bottom: 1.875rem;
}

.section--small {
  margin: 1rem 0;
}

.section--medium {
  margin: 1.25rem 0;
}

.section__header {
  margin-bottom: 1rem;

  h1,
  h2,
  h3 {
    flex: 1 1 auto;
    margin-bottom: 0;
  }

  .button-group {
    float: right;
  }

  &.section__header--compact {
    margin-bottom: 0.625rem;
  }

  &.section__header--collapse {
    margin: 0;
  }

  &.underlined {
    display: block;
    border-bottom: 1px solid $color-border;
    padding-bottom: $spacing--small;
  }
}

.section__body {
  margin-bottom: 1.875rem;
}

.section__footer {
  margin-top: 1.875rem;
}

/* stylelint-enable no-descending-specificity */
