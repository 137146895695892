@import 'styles/variables';

.header {
  font-weight: 300;
}

.items {
  z-index: 1;
  min-height: inherit;
  height: 100%;
}

.column {
  position: relative;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  border: 2px transparent solid;
  border-radius: 0.25rem;
  background-color: $color-gray--lightest;
  padding: $spacing--small $spacing--small 0 $spacing--small;

  &DragOver {
    border: 2px #56c6d8 dashed;
    background-color: #edf6fa;
  }

  &DragTarget {
    border: 2px $color-gray dashed;
  }
}

.columnHidden {
  min-height: 0;
  padding: 0;
  background: transparent;
}

.background {
  z-index: 0;
  position: absolute;
  height: calc(100% - 0.5rem);
  width: calc(100% - 1rem);
}
