@import 'styles/variables';

.sidebarIconContainer {
  @media only screen and (min-width: 768px) {
    display: flex;
  }

  svg {
    fill: $color-white;
  }
}

.sm {
  svg {
    width: 1rem;
    max-height: 1rem;
  }
}

.md {
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.lg {
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
