@import 'styles/variables';

.frogBox {
  font-size: $font-size--base;
  text-transform: none;
  display: flex;
  justify-content: center;
  background-color: $color-background-blue--light;
  padding: $spacing--medium $spacing--medium $spacing--medium $spacing--medium;
}

.frogIcon {
  margin-right: $spacing--medium;

  svg {
    height: 2rem;
    width: 2rem;
    fill: $color-brand-black;
  }
}

.frogContent {
  flex-grow: 1;
}

.marginBottom {
  margin-bottom: $spacing--default;
}
