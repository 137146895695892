// TODO: To be removed.

.role-fillers {
  .table {
    margin-bottom: 0;

    .th {
      padding-top: 0.25rem;
      vertical-align: middle;
    }

    .th,
    .td {
      padding: 0.5rem 0.75rem;

      &:first-child,
      &.filled-by {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .form-group {
    margin-bottom: 0.25rem;
  }

  .person {
    display: inline-block;
  }

  &.lead-link-assign {
    .table {
      width: 19rem;
    }

    h3 {
      padding-top: 1rem;
    }

    .table .headers {
      display: none;
    }

    .table .role-filler-view div {
      padding-top: 0;
    }
  }

  .filled-by {
    width: 15rem;
  }

  .filled-focus {
    color: $color-text--gray;
  }

  .buttons {
    width: 5rem;
    min-width: 5rem;
    text-align: center;

    .fa {
      font-size: $font-size--medium;
    }

    .fa-pencil {
      font-size: $font-size--base;
    }
  }

  .role-filler-view {
    cursor: default;
  }

  .exclude-from-meetings {
    width: 6.25rem;
  }

  .represents-role {
    color: $color-orange;
    font-size: 1.25rem;

    input[type='checkbox'],
    .fa {
      margin-right: 0.5rem;
    }
  }
}

li.no_roles {
  color: $color-text--gray;
}
