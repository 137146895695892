.member_actions {
  @media (max-width: $width-sm) {
    text-align: left;
    padding: 0;
  }
}

.settings {
  .fa-upload {
    margin-left: -$spacing--tiny;
    margin-right: $spacing--default;
    font-size: $font-size--medium;
  }
}
