@import 'styles/variables';

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  background-color: $color-brand-black;
  opacity: 0.5;
  z-index: 1050;
}

.dots {
  margin: auto;
}
